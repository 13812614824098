import { useTranslateImage } from "../../hooks/useTranslateImage";
import { Box, Typography } from "@mui/material";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import image1 from "../../images/experiences/wine_terrace/image1.jpg";
import image2 from "../../images/experiences/wine_terrace/image2.jpg";
import image3 from "../../images/experiences/wine_terrace/image3.jpg";
import image4 from "../../images/experiences/wine_terrace/image4.jpg";
import image5 from "../../images/experiences/wine_terrace/image5.jpg";
import image6 from "../../images/experiences/wine_terrace/image6.jpg";
import style from "./Experiences.module.css";

const images = [image1, image2, image3, image4, image5, image6];

export default function WineTerrace({ t }) {
  const currentImage = useTranslateImage(images);

  return (
    <Box
      className={"section_hidden section"}
      component="section"
      sx={{
        display: "grid",
        gridTemplateColumns: { lg: "2fr 1fr" },
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
        gap: "2rem",
        padding: { sm: "0 2rem", md: "0 3rem", lg: "0 2rem" },
      }}
    >
      <Box
        component="article"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          backgroundColor: "var(--color-background)",
          padding: { xs: "2rem", sm: "3rem 6rem", md: "3rem 10rem" },
          borderRadius: "1.4rem",
          boxShadow: {
            xs: "0 1rem var(--color-blue)",
            sm: "-1rem 1rem var(--color-blue)",
          },
          gridRow: { xs: "2", lg: "1" },
          width: { xs: "90%", sm: "100%" },
        }}
      >
        <Box>
          <Typography
            component="h2"
            sx={{
              gridColumn: "1/-1",
              justifySelf: "center",
              color: "var(--color-blue)",
              fontSize: "3rem",
              fontWeight: "600",
              textAlign: { xs: "center", sm: "left" },
              letterSpacing: "-0.5px",
              fontFamily: "var(--font-text)",
            }}
          >
            {t("component.wineTerrace")}
          </Typography>
          <Typography
            sx={{
              textTransform: "uppercase",
              letterSpacing: "0.5px",
              fontWeight: "600",
              textAlign: { xs: "center", sm: "left" },
              fontSize: "1.2rem",
              fontFamily: "var(--font-text)",
            }}
          >
            {t("component.byOncePueblos")}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "1.6rem",
            lineHeight: "1.6",
            fontFamily: "var(--font-text)",
          }}
        >
          {t("component.wineTerraceText")}
        </Typography>
        <Box
          sx={{
            alignSelf: "center",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: "2rem",
            marginBottom: "1rem",
          }}
        >
          <a
            className={`${style.reserve_btn} ${style.phone_btn}`}
            href="tel:6461627265"
          >
            <PhoneForwardedIcon />
            {t("component.reserve")}
          </a>
          <a
            className={`${style.reserve_btn} ${style.whatsapp_btn}`}
            href="https://api.whatsapp.com/send?phone=6461627265"
            target="_blank"
            rel="noreferrer"
          >
            <WhatsAppIcon />
            WhatsApp
          </a>
        </Box>
        <Box component="footer">
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontFamily: "var(--font-text)",
            }}
          >
            <em>{t("component.wineTerraceAvailability")}</em>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          overflow: "hidden",
          width: { xs: "90%", sm: "70%", md: "50%", lg: "100%" },
          justifySelf: "center",
        }}
      >
        {images.map((image) => (
          <img
            key={image}
            className={style.wineTerrace_carousel_img}
            src={image}
            alt="People enjoying a tasting at Sierra Vita winery"
            style={{ transform: `translateX(${currentImage * -100}%)` }}
          />
        ))}
      </Box>
    </Box>
  );
}
