import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { addItemsStorage, noStockAvailable } from "../../Redux/actions";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import AddedToCart from "../../Modals/AddedToCart/AddedToCart";
import styles from "./Wines.module.css";

export default function Wines() {
  const [t] = useTranslation("global");
  const wines = useSelector((state) => state.wines);
  const wineAddedToCart = useSelector((state) => state.lastWineAddedToCart);
  const dispatch = useDispatch();
  const [currentWine, setCurrentWine] = useState(0);

  const displayNextWine = () => {
    if (currentWine === wines.length - 1) setCurrentWine(0);
    else setCurrentWine((prev) => ++prev);
  };

  const displayPreviousWine = () => {
    if (currentWine === 0) setCurrentWine(wines.length - 1);
    else setCurrentWine((prev) => --prev);
  };

  const addToCart = (stock, wine) => {
    if (stock > 0) {
      dispatch(addItemsStorage(wine));
    } else dispatch(noStockAvailable(wine));
  };

  return (
    <>
      <Box
        id="section--wines"
        component="section"
        sx={{
          backgroundColor: "var(--color-background)",
          color: "var(--color-text-light)",
          padding: "4rem 0",
          position: "relative",
        }}
      >
        <Box
          className={"section_hidden section"}
          sx={{
            display: "flex",
            justifyContent: `${wines.length === 0 ? "center" : ""}`,
            alignItems: "center",
            overflow: "hidden",
            minHeight: "40rem",
          }}
        >
          {wines.length === 0 ? (
            <div className={styles["lds-dual-ring"]}></div>
          ) : (
            <>
              <button
                onClick={displayNextWine}
                className={`${styles.carousel_btn} ${styles.right_btn}`}
              >
                <ChevronRightOutlinedIcon sx={{ fontSize: "3.4rem" }} />
              </button>
              {wines.map((wine, i) => (
                <Box
                  sx={{
                    minWidth: "100%",
                    display: "grid",
                    justifyContent: { xs: "center" },
                    gridTemplateColumns: { xs: "100%", md: "1fr 2fr" },
                    gap: { xs: "2rem", sm: "4rem" },
                    padding: "5rem",
                    transition: "all 0.8s",
                    position: "relative",
                    transform: `translateX(${currentWine * -100}%)`,
                  }}
                  className={`${styles.wine_slide} `}
                  id={`wine--${i}`}
                  key={wine._id}
                >
                  <Typography
                    component="span"
                    sx={{
                      display: { sm: "none" },
                      fontSize: "3rem",
                      color: "var(--color-blue)",
                      position: "absolute",
                      top: "0",
                      right: "3rem",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    ${wine.price}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      borderRight: {
                        xs: "none",
                        md: "0.6rem solid var(--color-blue)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        alignSelf: "flex-start",
                      }}
                    >
                      <Typography
                        component="h2"
                        gutterBottom
                        sx={{
                          fontSize: "2.6rem",
                          letterSpacing: "0.5px",
                          fontFamily: "var(--font-text)",
                        }}
                      >
                        Sierra Vita
                      </Typography>
                      <Typography
                        component="h3"
                        gutterBottom
                        sx={{
                          color: "var(--color-blue)",
                          fontSize: "2.2rem",
                          fontFamily: "var(--font-text)",
                        }}
                      >
                        {wine.name}
                      </Typography>
                    </Box>
                    <img
                      className={styles.wine_img}
                      src={`https://${wine.images}`}
                      alt={`${wine.name} wine`}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: { sm: "flex" },
                      gap: "2rem",
                      // padding: "0 2rem",
                      color: "var(--color-text-light)",
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: "none", sm: "flex" },
                        flexDirection: "column",
                        width: { sm: "50%" },
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        component="h3"
                        gutterBottom
                        sx={{
                          fontSize: "2rem",
                          color: "var(--color-blue)",
                          fontFamily: "var(--font-text)",
                        }}
                      >
                        {t("component.description")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "1.6rem", sm: "1.8rem" },
                          lineHeight: "1.8",
                          fontFamily: "var(--font-text)",
                        }}
                      >
                        {t("component.language") === "EN"
                          ? wine.descriptionEN
                          : wine.descriptionES}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: { xs: "2rem" },
                        width: { sm: "50%" },
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          display: { xs: "none", sm: "inline-block" },
                          fontSize: "3rem",
                          color: "var(--color-blue)",
                          alignSelf: "flex-end",
                          fontFamily: "var(--font-text)",
                        }}
                      >
                        ${wine.price}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "3rem",
                        }}
                      >
                        <button
                          onClick={() => addToCart(wine.stock, wine)}
                          className={`${styles.wine_btn} ${styles.cart_btn}`}
                        >
                          {t("component.addToCart")}
                        </button>
                        <Link
                          className={styles.btn_link}
                          to={`/detail/${wine._id}`}
                        >
                          <button
                            className={`${styles.wine_btn} ${styles.detail_btn}`}
                          >
                            {t("component.viewDetail")}
                          </button>
                        </Link>
                      </Box>
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "1.8rem",
                          alignSelf: { xs: "center", sm: "flex-end" },
                          fontFamily: "var(--font-text)",
                        }}
                      >
                        {`${wine.stock}` === "0"
                          ? `${t("component.out")}`
                          : `${wine.stock} ${t("component.available")}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
              <button
                onClick={displayPreviousWine}
                className={`${styles.carousel_btn} ${styles.left_btn}`}
              >
                <ChevronLeftOutlinedIcon sx={{ fontSize: "3.4rem" }} />
              </button>
            </>
          )}
        </Box>
      </Box>
      {/* {Object.keys(wineAddedToCart).length > 0 && ( */}
      <AddedToCart wine={wineAddedToCart} />
      {/* )} */}
    </>
  );
}
