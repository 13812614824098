import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import AddressForm from "./AdressFrom";
import "./App.module.css";
import { URL } from "../Redux/actions";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(
  "pk_test_51MS0vKAcFlYSWFKMDeh6rd8l2Qr5RdVqAZCyEpE6591we40ToOSgkfighgC7ZiqUW8vrhaWoS7hMx8A45WORMGbw007KRaGGgu"
);

export default function Stripe() {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(`${URL}/payment/create-payment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        items: [
          {
            id: "c3587ebb-d6a6-483b-b98e-6f18e6c0b285",
            quantity: 3,
            price: 100,
          },
          {
            id: "d087ba73-987b-4989-8e51-bc1e29f809e5",
            quantity: 4,
            price: 100,
          },
        ],
      }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <AddressForm />
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}
