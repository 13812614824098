import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { subscribeToNewsletter } from "../../Redux/actions";
import { LogoName } from "../../assets/LogoName";
import newsLetter from "../../../images/Newsletter.jpg";
import style from "./Newsletter.module.css";
import SubToNewsletter from "../../Modals/SubToNewsletter/SubToNewsletter";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";



export default function Newsletter() {
  const [t] = useTranslation("global");

  const [input, setInput] = useState({ name: "", lastname: "", email: "" });
  const [phone, setPhone] = useState("");
  const [selectLenguage, setSelectLenguage] = useState(true);
  const [subscriber, setSubscriber] = useState({});

  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handlePhone = (e) => {
    setPhone(e);
  };

  const handleSelectLenguage = (e) => {
    e.target.id === "espaniol"
      ? setSelectLenguage(false)
      : setSelectLenguage(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const resp = await subscribeToNewsletter({
      ...input,
      phone,
      selectLenguage: selectLenguage,
    });

    if (resp.status === 200) setSubscriber(resp.data);
    else if (resp.status === 400)
      setSubscriber({ error: t("component.wrong") });
  };

  return (
    <>
      <Box
        className="section section_hidden"
        component="section"
        sx={{
          color: "var(--color-text-light)",
          height: "100vh",
          padding: { md: "4rem", lg: "8rem" },
          display: { xs: "flex", md: "grid" },
          flexDirection: "column",
          gap: { xs: "4rem", md: "0" },
          gridTemplateColumns: "1fr 1fr",
          justifyContent: "center",
          justifyItems: "center",
          alignItems: "center",
          backgroundImage: `linear-gradient(
          rgba(34, 34, 34, 0.7),
          rgba(34, 34, 34, 0.7)
        ), url(${newsLetter})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            padding: { xs: "0", sm: "0 2rem", md: "4rem" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              padding: { xs: "0", sm: "0 2rem", md: "3.5rem" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "rgba(34, 34, 34, 0.7)",
              marginBottom: "1rem",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "4rem", sm: "5rem" },
                fontFamily: "var(--font-title)",
                marginBottom: "1rem",
              }}
            >
              Club
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <LogoName color="#fefdfd" height="6rem" />
            </Box>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <LogoName color="#fefdfd" height="4rem" />
            </Box>
          </Box>
          <Box
            sx={{
              padding: { xs: "0 2rem", sm: "0 4rem", md: "4rem" },
              alignSelf: { sm: "start", md: "center" },
            }}
          >
            <Typography
              sx={{
                display: { xs: "none", sm: "block" },
                fontSize: { xs: "1.8rem", sm: "2rem" },
                fontFamily: "var(--font-text)",
                textAlign: "center",
              }}
            >
              {t("component.newsletterText")}
            </Typography>
            <Typography
              sx={{
                display: { sm: "none" },
                fontSize: { xs: "1.8rem", sm: "2rem" },
                fontFamily: "var(--font-text)",
                textAlign: "center",
              }}
            >
              {t("component.newsletterTextMobile")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            padding: { xs: "0 2rem", md: "4rem" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <form className={style.subscribe_form} onSubmit={handleSubmit}>
            <input
              onChange={handleInput}
              className={style.subscribe_input}
              type="text"
              name="name"
              value={input.name}
              placeholder={t("component.name")}
            />
            <input
              onChange={handleInput}
              className={style.subscribe_input}
              type="text"
              name="lastname"
              value={input.lastname}
              placeholder={t("component.lastName")}
            />
            <input
              onChange={handleInput}
              className={style.subscribe_input}
              type="email"
              name="email"
              value={input.email}
              placeholder="example@example.com"
            />
            <div>
              <PhoneInput
                onChange={handlePhone}
                className={style.subscribe_input}
                //type="tel"
                name="phone"
                value={input.phone}
                placeholder="555 5555"
              />
            </div>
            <div>
              <Typography
                sx={{
                  fontSize: { xs: "1rem", sm: "1.5rem" },
                  fontFamily: "var(--font-text)",
                  textAlign: "center",
                  marginBottom: "2rem",
                }}
              >
                {t("component.selectLenguageClubWineText")}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <div className={style.subscribe_div}>
                  <input
                    type="radio"
                    name="selected"
                    id="english"
                    checked={selectLenguage}
                    onClick={(e) => {
                      handleSelectLenguage(e);
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "1rem", sm: "1.5rem" },
                      fontFamily: "var(--font-text)",
                    }}> {t("component.eng")}
                  </Typography>
                </div>
                <div className={style.subscribe_div}>
                  <input
                    type="radio"
                    name="selected"
                    id="espaniol"
                    onClick={(e) => {
                      handleSelectLenguage(e);
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "1rem", sm: "1.5rem" },
                      fontFamily: "var(--font-text)",
                      marginBottom: "2rem",
                    }}> {t("component.spa")}
                  </Typography>
                </div>
              </Box>
            </div>
            <button type="submit" className={style.subscribe_btn}>
              {t("component.subscribe")}
            </button>
          </form>
        </Box>
        <SubToNewsletter
          subscriber={subscriber}
          setSubscriber={setSubscriber}
          email={input.email}
          setInput={setInput}
        />
      </Box>
    </>
  );
}
