import { Box, Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import bannerImg from "../../../images/banner.jpg";
import style from "./Banner.module.css";

export default function Banner() {
  const [t] = useTranslation("global");
  function handleButtonClick() {
    window.open('https://www.lmawines.com/shop?Category=SierraVita', '_blank');
  }

  return (
    <Box
      className={style.sizetxt}
      id="section--baner"
      component="section"
      sx={{
        maxHeight: "90vh",
        position: "relative",
      }}
    >
      <div className={style.overlay}></div>
      <img
        src={bannerImg}
        className={style.bannerimg}
        id="banner-img"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "0.8rem", sm: "2.5rem", md: "3rem" },
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top:  { xs: "40%", sm: "48%"},
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "100",
        }}
      >
        <Typography
              sx={{
                fontSize: {
                  xs: "1.2rem",
                  sm: "2.4rem", 
                  md: "4rem",
                },
                textAlign: "center",
                fontFamily: "var(--font-title)",
                color: "var(--color-text-light)",
              }}
            >
              {t("component.bannerText")}
            </Typography>
        <Button
          sx={{
            textTransform: "none",
            fontSize: { 
              xs: "1rem",
              sm: "1.5rem", 
              md: "2rem",
            },
            fontFamily: "var(--font-text)",
            padding: {xs: "0.3rem 1.8rem 0.6rem", sm: "0.8rem 2.2rem 1.2rem"},
            backgroundColor: "var(--color-carousel-btn)",
            color: "var(--color-text-light)",
            borderRadius: "100rem",
            "&:hover": {
              backgroundColor: "rgba(97, 166, 171, 0.685)",
            },
          }}
          onClick={handleButtonClick}
        >
          {t("component.bannerBtn")}
        </Button>
      </Box>
    </Box>
  );
}
