import React from 'react'
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import style from './IconCart.module.css'
import { getTotalItems } from '../Redux/actions';

const IconCart = () => {

  // const [items, setItems] = React.useState(0)
  const itemsShoppingCart = useSelector(state => state.itemsShoppingCart);
  const TotalItems = useSelector((state) => state.totalItemsCart);
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getTotalItems());
  }, [itemsShoppingCart.length, TotalItems])

  return (
    <Box sx={{ display: 'flex', marginLeft: { xs: '0px', sm: '50px' }, marginRight: { xs: '0px', sm: '30px' } }}>
      <Box className={style.icon_container} >
        <LocalGroceryStoreIcon className={style.icon} />
      </Box>
      {TotalItems > 0 &&
        <Box className={style.items_length}>{TotalItems}</Box>
      }
    </Box>
  )
}

export default IconCart