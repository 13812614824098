import React from "react";
import { Grid } from '@mui/material';
import NewsletterEN from "./NewsletterEN";
import NewsletterES from "./NewsletterES";
import SubsList from "./SubsList";

function Newsletter() {
  return (
    <Grid
      container
      spacing={{ sm: 2, md: 4 }}
      alignItems="center"
      justifyContent="center"
      sx={{backgroundColor:"var(--color-background-admin)"}}
    >
      <Grid item xs={12} sm={6} md={6} >
        <NewsletterEN />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <NewsletterES />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <SubsList />
      </Grid> 
    </Grid>
  )
}
export default Newsletter;