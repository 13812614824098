import React, { useState } from "react";
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useDispatch } from "react-redux";
import { postAdmin } from "../../Redux/actions";

function validate(input) {
  let errors= {};
  if (!input.name || input.name?.length <= 2 ) {
    errors.name = "A name of 3 letters or more is required";
  }
  if (!input.lastName || input.lastName?.length === 0) {
    errors.lastName = "A lastname is required";
  }
  if (!input.userName || input.userName?.length <= 3) {
    errors.userName = "A name of 4 letters or more is required";
  }
  if (!input.email || input.email?.length === 0 ||
    !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(input.email)) {
    errors.email = "A valid email is required";
  }
  if (!input.password || input.password?.length === 0 ||
    !/^(?=.*\d)(?=.*[a-z])(?=.*?[0-9]).{6,}$/.test(input.password)) {
    errors.password = "A password at least one letter and one number is required (least 6 digits)";
  }

  return errors;
}

function CreateAdmin() {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const [input, setInput] = useState({
    name: "",
    lastName: "",
    userName: "",
    email: "",
    password: ""
  })

  const defaultValues = () => ({
    name: "",
    lastName: "",
    userName: "",
    email: "",
    password: ""
  });

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }))

    setErrors(validate({
      ...input,
      [e.target.name] : e.target.value
    }))
  }

  let err = (
    errors.name ||
    errors.lastName ||
    errors.userName ||
    errors.email ||
    errors.password
  ) ? true: false

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(postAdmin(input))
    //validation (myUser, myPassword)
    //action 
    setInput({
      name: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
    })
  };

  return (
    <Box
    direction="column"
    component="form"
    defaultValues={defaultValues}
    onSubmit={e => onSubmit(e)}
    sx={{
      '& .MuiTextField-root': { 
        m: 1, width: '40ch' 
      },
    }}
    >
      <Stack direction="column" alignItems="center" spacing={2}>
      <Typography variant="h4" component="h4" margin={2}sx={{
      fontSize: { xs: "2rem", sm: "3rem" },
      fontFamily: "var(--font-text)",
      color: "var(--color-text-dark)",
      fontWeight: "900"
      }}> Add new Admin
      </Typography>

      <TextField
      name="name"
      label="Name:"
      InputProps={{
        style: {
          fontSize: 20,
          color:"var(--color-text-dark)",
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 20,
        },
      }}
      onChange={e => handleChange(e)}
      error={errors.name} helperText={errors.name}
      />
      <TextField
        name="lastName"
        label="Lastname:"
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
        error={errors.lastName} helperText={errors.lastName}
      />
      <TextField
        name="userName"
        label="Username:"
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
        error={errors.userName} helperText={errors.userName}
      />
      <TextField
        name="email"
        label="Email:"
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
        error={errors.email} helperText={errors.email}
      />
      <TextField
        name="password"
        label="Password:"
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
        error={errors.password} helperText={errors.password}
      />
      {err ? <span> Must fill all the fields correctly </span> : null }
      <Button 
        disabled={err} 
        type="submit" 
        onClick={onSubmit}
        sx={{ backgroundColor: "var(--color-gray)", marginRight: "1rem", padding: "1rem", border: "1px solid var(--color-text-dark)" }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1rem", sm: "1.5rem" },
              fontFamily: "var(--font-text)",
              color: "var(--color-text-dark)",
              fontWeight: "900",
            }}> Save Admin
          </Typography>
      </Button>
      </Stack>
    </Box>
  );
};

export default CreateAdmin;