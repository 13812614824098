import React from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentCheck from "./CheckoutForms/PaymentCheck";
import { Box } from "@mui/material";
import { URL } from "../Redux/actions";

const tokenPublic = "pk_live_51MfY3fCorPSDpHq8ovhygNtFKwYMyMJyEgEMi03j9OwszExw4JLREbITcA2Vkj1uGX4qwtR9Ynk3EkCS4GoFTDYR00NSNAb3ii";

const stripePromise = loadStripe(tokenPublic);

const CheckStripe = ({ setView }) => {
  const [clientSecret, setClientSecret] = React.useState("");
  const WinesCart = useSelector((state) => state.itemsShoppingCart);
  const TotalPrice = useSelector((state) => state.totalPriceProducts);
  const ShipmentInformation = useSelector((state) => state.shipmentInformation);

  React.useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(`${URL}/payment/create-payment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        items: WinesCart,
        shipmentInformation: ShipmentInformation,
      }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const options = {
    clientSecret,
  };

  return (
    <Box>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <PaymentCheck
            totalPrice={TotalPrice}
            setView={setView}
            shipmentInformation={ShipmentInformation}
          />
        </Elements>
      )}
    </Box>
  );
};

export default CheckStripe;
