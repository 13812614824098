import { useTranslateImage } from "../../hooks/useTranslateImage";
import { Box, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import WineBarIcon from "@mui/icons-material/WineBar";
import GroupIcon from "@mui/icons-material/Group";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import DeckIcon from "@mui/icons-material/Deck";
import TapasIcon from "@mui/icons-material/Tapas";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ClassIcon from "@mui/icons-material/Class";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import image1 from "../../images/experiences/tasting/image1.jpg";
import image2 from "../../images/experiences/tasting/image2.jpg";
import image3 from "../../images/experiences/tasting/image3.jpg";
import image4 from "../../images/experiences/tasting/image4.jpg";
import image5 from "../../images/experiences/tasting/image5.jpg";
import style from "./Experiences.module.css";

const images = [image1, image2, image3, image4, image5];

export default function TastingOptions({ t }) {
  const currentImage = useTranslateImage(images);

  return (
    <Box
      component="section"
      sx={{
        display: "grid",
        gridTemplateColumns: { lg: "1fr 2fr" },
        justifyContent: "center",
        justifyItems: { xs: "center", sm: "start" },
        alignItems: "center",
        gap: "2rem",
        padding: { sm: "0 2rem", md: "0 3rem", lg: "0 2rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          overflow: "hidden",
          width: { xs: "90%", sm: "70%", md: "50%", lg: "100%" },
          justifySelf: "center",
        }}
      >
        {images.map((image) => (
          <img
            key={image}
            className={style.tasting_carousel_img}
            src={image}
            alt="People enjoying a tasting at Sierra Vita winery"
            style={{ transform: `translateX(${currentImage * -100}%)` }}
          />
        ))}
      </Box>
      <Box
        sx={{
          backgroundColor: "var(--color-background)",
          display: { xs: "flex", sm: "grid" },
          flexDirection: "column",
          gridTemplateColumns: "1fr 1fr",
          rowGap: "3rem",
          padding: { xs: "1rem 2rem", sm: "2rem", md: "3rem 6rem" },
          borderRadius: "1.4rem",
          boxShadow: {
            xs: "0 1rem var(--color-blue)",
            sm: "1rem 1rem var(--color-blue)",
          },
          "&>article>h2": { fontSize: "2rem", marginBottom: "1rem" },
          "&>article>p, &>article>span, &>article>ul": {
            fontSize: "1.4rem",
          },
          width: { xs: "90%", sm: "100%" },
        }}
      >
        <Box component="article">
          <Typography
            component="h2"
            sx={{
              color: "var(--color-blue)",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              fontFamily: "var(--font-text)",
            }}
          >
            <ClassIcon sx={{ fontSize: "2.4rem" }} />{" "}
            {t("component.classicTasting")}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontFamily: "var(--font-text)",
            }}
          >
            {t("component.includes")}:
          </Typography>
          <ul className={style.tasting_list}>
            <li>
              <WineBarIcon /> {t("component.3labels")} (2 oz.).{" "}
            </li>
            <li>
              <GroupIcon />
              {t("component.availabilityFrom1Person")}
            </li>
            <li>
              <AttachMoneyIcon />
              {t("component.pricePerPerson")} $400.00 pesos.
            </li>
          </ul>
        </Box>
        <Box component="article">
          <Typography
            component="h2"
            sx={{
              color: "var(--color-blue)",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <DeckIcon sx={{ fontSize: "2.4rem" }} />
            {t("component.boutiqueTasting")}
          </Typography>
          <Typography component="span">{t("component.includes")}:</Typography>
          <ul className={style.tasting_list}>
            <li>
              <WineBarIcon />
              {t("component.5labels")} (2 oz.).
            </li>
            <li>
              <GroupIcon />
              {t("component.availabilityFrom1Person")}
            </li>
            <li>
              <AttachMoneyIcon />
              {t("component.pricePerPerson")} $580.00 pesos.
            </li>
          </ul>
        </Box>
        <Box component="article">
          <Typography
            component="h2"
            sx={{
              color: "var(--color-blue)",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <StarIcon sx={{ fontSize: "2.4rem" }} />{" "}
            {t("component.premiumTasting")}
          </Typography>
          <Typography component="span">{t("component.includes")}:</Typography>
          <ul className={style.tasting_list}>
            <li>
              <WarehouseIcon />
              {t("component.fieldExplanation")}
            </li>
            <li>
              <WineBarIcon />
              {t("component.5labels")} (2 oz).
            </li>
            <li>
              <TapasIcon />
              {t("component.cheeseBoard")}
            </li>
            <li>
              <LocalDrinkIcon />
              {t("component.bottleWater")}
            </li>
            <li>
              <GroupIcon />
              {t("component.availabilityFrom4People")}
            </li>
            <li>
              <AttachMoneyIcon />
              {t("component.pricePerPerson")} $750.00 pesos.
            </li>
          </ul>
        </Box>
        <Box
          component="article"
          sx={{
            overflow: "hidden",
            position: "relative",
            "&::before": {
              content: `'${t("component.recommended")}'`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: { md: "absolute" },
              width: { md: "20rem" },
              height: "2rem",
              backgroundColor: "var(--color-blue)",
              fontSize: "1.2rem",
              fontWeight: "bold",
              textTransform: "uppercase",
              letterSpacing: "0.5px",
              transform: { md: "rotate(45deg)" },
              right: "-5rem",
              top: "3.6rem",
            },
          }}
        >
          <Typography
            component="h2"
            sx={{
              color: "var(--color-blue)",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              fontFamily: "var(--font-text)",
            }}
          >
            <WorkspacePremiumIcon
              sx={{
                fontSize: "2.4rem",
                fontFamily: "var(--font-text)",
              }}
            />{" "}
            {t("component.oncePueblosTasting")}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontFamily: "var(--font-text)",
            }}
          >
            {t("component.includes")}:
          </Typography>
          <ul className={style.tasting_list}>
            <li>
              <WarehouseIcon />
              {t("component.fieldExplanation")}
            </li>
            <li>
              <WineBarIcon />
              {t("component.4labels")} (3 oz).
            </li>
            <li>
              <TapasIcon />4 {t("component.snacks")}
            </li>
            <li>
              <LocalDrinkIcon />
              {t("component.bottleWater")}
            </li>
            <li>
              <GroupIcon />
              {t("component.availabilityFrom2People")}
            </li>
            <li>
              <AttachMoneyIcon />
              {t("component.pricePerPerson")} $1,100.00 pesos.
            </li>
          </ul>
        </Box>
        <Box
          sx={{
            gridColumn: "1/-1",
            justifySelf: "center",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: "2rem",
            marginBottom: "1rem",
          }}
        >
          <a
            className={`${style.reserve_btn} ${style.phone_btn}`}
            href="tel:6461627265"
          >
            <PhoneForwardedIcon />
            {t("component.reserve")}
          </a>
          <a
            className={`${style.reserve_btn} ${style.whatsapp_btn}`}
            href="https://api.whatsapp.com/send?phone=6461627265"
            target="_blank"
            rel="noreferrer"
          >
            <WhatsAppIcon />
            WhatsApp
          </a>
        </Box>
        <Box
          component="footer"
          sx={{
            gridColumn: "1/-1",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { md: "space-between" },
            alignItems: { xs: "flex-start", md: "center" },
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <em>{t("component.tastingWeekdays")}</em>
            <em>{t("component.tastingWeekends")}</em>
          </Typography>
          <Typography sx={{ fontSize: "1.2rem" }}>
            <em>{t("component.winesSubjectToChange")}</em>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
