import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { emptyWineAddedToCart } from "../../Redux/actions";
import style from "./AddedToCart.module.css";

export default function AddedToCart({ wine }) {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const wineAddedToCart = useSelector((state) => state.lastWineAddedToCart);

  const closeModal = () => dispatch(emptyWineAddedToCart());

  if (wine.stock < 1)
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            alignItems: "center",
            padding: "2rem",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: `translate(-50%, -50%) ${
              Object.keys(wineAddedToCart).length > 0 ? "scale(1)" : "scale(0)"
            }`,
            backgroundColor: "var(--color-background)",
            color: "var(--color-text-light)",
            boxShadow: "0 1rem 1rem rgba(0,0,0,0.8)",
            zIndex: "100",
            transition: "all 0.3s",
          }}
        >
          <Typography sx={{ fontSize: "2rem" }}>
            {t("component.language") === "EN"
              ? `${wine.name} ${t("component.noStock")}`
              : `${t("component.noStock")} ${wine.name}`}
          </Typography>
          <button onClick={closeModal} className={style.wine_btn}>
            {t("component.gotIt")}
          </button>
        </Box>
        <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: `${
              Object.keys(wineAddedToCart).length > 0 ? "block" : "none"
            }`,
            zIndex: "99",
          }}
        ></Box>
      </>
    );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          alignItems: "center",
          padding: "2rem",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: `translate(-50%, -50%) ${
            Object.keys(wineAddedToCart).length > 0 ? "scale(1)" : "scale(0)"
          }`,
          backgroundColor: "var(--color-background)",
          color: "var(--color-text-light)",
          boxShadow: "0 1rem 1rem rgba(0,0,0,0.8)",
          zIndex: "100",
          transition: "all 0.3s",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "2rem", textAlign: "center" }}>
            {`${wine.name} ${t("component.addedToCart")}`}
          </Typography>
          <img
            className={style.wine_img}
            src={`https://${wine.images}`}
            alt={wine.name}
          />
        </Box>
        <button onClick={closeModal} className={style.wine_btn}>
          {t("component.gotIt")}
        </button>
      </Box>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: `${
            Object.keys(wineAddedToCart).length > 0 ? "block" : "none"
          }`,
          zIndex: "99",
        }}
      ></Box>
    </>
  );
}
