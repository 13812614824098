import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Box, FormControl, InputLabel, ListItemAvatar, MenuItem, Select } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EditForm from './EditForm';
import { getAllWinesAdmin, getWineDetailInfoAdmin } from '../../Redux/actions';
import style from './Edit.module.css'

export default function EditWines() {
  const [allWines, setAllWines] = React.useState([]);
  const [drawerEdit, setDrawerEdit] = React.useState(false);
  const [selectStateSort, setSelectStateSort] = React.useState('');
  const [selectStateFilter, setSelectStateFilter] = React.useState('');

  const AllWinesStore = useSelector(state => state.AllProductsInDB);
  const dispatch = useDispatch();

  const handleChangeSelectSort = (e) => {
    setSelectStateSort(e.target.value)
    switch (e.target.value) {
      case 'MenStock':
        allWines.sort((a, b) => a.stock - b.stock)
        return setAllWines(allWines);
      case "MayStock":
        allWines.sort((a, b) => b.stock - a.stock)
        return setAllWines(allWines);
      case "MayPri":
        allWines.sort((a, b) => b.price - a.price)
        return setAllWines(allWines);
      case "MenPri":
        allWines.sort((a, b) => a.price - b.price)
        return setAllWines(allWines);
      default:
        break;
    }
  }

  const handleChangeSelectFilter = (e) => {
    setSelectStateFilter(e.target.value)
    if (e.target.value === "active") {
      let wines = AllWinesStore.filter((wines) => { return wines.isActive === true })
      return setAllWines(wines)
    } else if (e.target.value === "noActive") {
      let wines = AllWinesStore.filter((wines) => { return wines.isActive === false })
      if (wines.length === 0) {
        return setAllWines(null)
      } else setAllWines(wines)
    } else return setAllWines(AllWinesStore)
  }

  const editItem = (id) => {
    dispatch(getWineDetailInfoAdmin(id))
    setDrawerEdit(true)
  }

  React.useEffect(() => {
    dispatch(getAllWinesAdmin())
    setAllWines(AllWinesStore)
  }, [AllWinesStore.length])

  return (
    <>
      <EditForm openDrawer={drawerEdit} closeDrawer={setDrawerEdit} />
      <Box className={style.box_container} >
        <Box>
          <FormControl sx={{ m: 1, minWidth: 150, marginBottom: '30px' }}>
            <InputLabel id="demo-simple-select-label" sx={{ color: '#000', fontSize: '1.6rem' }}>Sort</InputLabel>
            <Select
              sx={{ color: '#000', fontSize: '1.6rem' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectStateSort}
              label="sort"
              onChange={(e) => { handleChangeSelectSort(e) }}
            >
              <MenuItem value='MayStock' sx={{ color: '#000', fontSize: '1.6rem' }}>Mayor Stock</MenuItem>
              <MenuItem value='MenStock' sx={{ color: '#000', fontSize: '1.6rem' }}>Menor Stock</MenuItem>
              <MenuItem value='MayPri' sx={{ color: '#000', fontSize: '1.6rem' }}>Mayor Precio</MenuItem>
              <MenuItem value='MenPri' sx={{ color: '#000', fontSize: '1.6rem' }}>Menor Precio</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 150, marginBottom: '30px' }}>
            <InputLabel id="demo-simple-select-label" sx={{ color: '#000', fontSize: '1.6rem' }}>Filter</InputLabel>
            <Select
              sx={{ color: '#000', fontSize: '1.6rem' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectStateFilter}
              label="filter"
              onChange={(e) => { handleChangeSelectFilter(e) }}
            >
              <MenuItem value='all' sx={{ color: '#000', fontSize: '1.6rem' }}>All</MenuItem>
              <MenuItem value='active' sx={{ color: '#000', fontSize: '1.6rem' }}>Active</MenuItem>
              <MenuItem value='noActive' sx={{ color: '#000', fontSize: '1.6rem' }}>No Active</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <TableContainer component={Paper} className={style.table_container} >
          <Table aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Name</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Price</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Stock</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Type</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Active</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Edit</TableCell>
              </TableRow>
            </TableHead>
            {allWines === null ? <h1>No hay vinos para mostrar </h1> : allWines.length < 1 ? <h1>loading</h1> :
              <TableBody>
                {allWines.map((wine) => (
                  <TableRow
                    key={wine._id}
                    className={style.table_row}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">
                      <ListItemAvatar>
                        <Avatar alt={wine.name} src={`https://${wine.images}`} />
                      </ListItemAvatar>
                    </TableCell>
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{wine.name}</TableCell>
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">${wine.price}</TableCell>
                    {wine.stock > 0 ? 
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{wine.stock}</TableCell>
                    :
                    <TableCell sx={{ fontSize: '1.5rem', fontWeight: '600' }} align="center">{wine.stock}</TableCell>
                    }
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{wine.type}</TableCell>
                    {wine.isActive ?
                      <TableCell sx={{ fontSize: '1.5rem', color: 'green', fontWeight: '600' }} align="center">Active</TableCell>
                      :
                      <TableCell sx={{ fontSize: '1.5rem', color: 'red' }} align="center">No Active</TableCell>
                    }
                    <TableCell align="center">
                      <BorderColorIcon onClick={() => editItem(wine._id)} sx={{ color: '#61a6ab', cursor: 'pointer' }} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            }
          </Table>
        </TableContainer >
      </Box>
    </>
  );
}
