import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Grid, Button, Stack, TextField, Typography, OutlinedInput, InputAdornment, InputLabel, FormControl } from '@mui/material';
import { postWine } from "../../Redux/actions";

function validate(input) {
  let errors= {};
  if (!input.name || input.name?.length <= 0) {
    errors.name = "Please enter the wine name";
  }
  if (!input.type || input.type?.length <= 0) {
    errors.type = "Please enter the wine type";
  }

  //en
  if (!input.descriptionEN || input.descriptionEN?.length <= 0) {
    errors.descriptionEN = "Please enter the wine description in English";
  }
  if (!input.colorEN || input.colorEN?.length <= 0) {
    errors.colorEN = "Please enter the wine color in English";
  }
  if (!input.noseEN || input.noseEN?.length <= 0) {
    errors.noseEN = "Please enter the wine nose in English";
  }
  if (!input.mouthEN || input.mouthEN?.length <= 0) {
    errors.mouthEN = "Please enter the wine mouth in English";
  }
  if (!input.pairingEN || input.pairingEN?.length <= 0) {
    errors.pairingEN = "Please enter the wine pairing in English";
  }
  //esp
  if (!input.descriptionES || input.descriptionES?.length <= 0) {
    errors.descriptionES = "Please enter the wine description in Spanish";
  }
  if (!input.colorES || input.colorES?.length <= 0) {
    errors.colorES = "Please enter the wine color in Spanish";
  }
  if (!input.noseES || input.noseES?.length <= 0) {
    errors.noseES = "Please enter the wine nose in Spanish";
  }
  if (!input.mouthES || input.mouthES?.length <= 0) {
    errors.mouthES = "Please enter the wine mouth in Spanish";
  }
  if (!input.pairingES || input.pairingES?.length <= 0) {
    errors.pairingES = "Please enter the wine pairing in Spanish";
  }
  //numbers
  if (!input.cont || input.cont < 1 ) {
    errors.cont = "The wine volume must be equal or greater than 1ml";
  }
  if (!input.alcoholVol || input.alcoholVol < 0 ) {
    errors.alcoholVol = "The alcoholVol must be equal or greater than 0";
  }
  if (!input.temp || input.temp.length === 0) {
    errors.temp = "Enter wine temperature";
  }
  if (!input.price || input.price < 1 ) {
    errors.price = "The wine price must be equal or greater than 0";
  }
  if (!input.stock || input.stock < 0 ) {
    errors.stock = "The wine stock must be equal or greater than 0";
  }

  return errors;
}

function CreateWine() {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const [input, setInput] = useState({
    name : "",
    type: "",
    stock: 0,
    price: 0,
    alcoholVol: 0,
    descriptionEN: "",
    descriptionES: "",
    // isActive: true,
    cont: 0,
    temp: 0,
    noseEN: "",
    noseES: "",
    mouthEN: "",
    mouthES: "",
    pairingEN: "",
    pairingES: "",
    images: [],
  })

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    })
    setErrors(validate({
      ...input,
      [e.target.name] : e.target.value
    }))
  }

  function handleChangeImages(e) {
    const { name } = e.target;
    setInput({ 
      ...input, 
      [name]: e.target.files[0], 
    });
  }

  let err = (
    errors.name ||
    errors.type ||
    errors.descriptionEN ||
    errors.colorEN ||
    errors.noseEN ||
    errors.mouthEN ||
    errors.pairingEN ||
    errors.descriptionES ||
    errors.colorES ||
    errors.noseES ||
    errors.mouthES ||
    errors.pairingES ||
    errors.cont ||
    errors.alcoholVol ||
    errors.temp ||
    errors.stock ||
    errors.price
  ) ? true: false
  
  const handleSubmit = async (e) =>{
    e.preventDefault()
    dispatch(postWine(input))
    alert("Wine created")
  }

 return(
  <Box
    direction="column"
    component="form"
    onSubmit={e => handleSubmit(e)}
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      '& .MuiTextField-root': { 
        m: 1, width: '40ch' 
      },
    }}
  >
    <Stack direction="column" alignItems="center" spacing={2}>
    <Typography variant="h4" component="h4" margin={2}sx={{
      fontSize: { xs: "2rem", sm: "3rem" },
      fontFamily: "var(--font-text)",
      color: "var(--color-text-dark)",
      fontWeight: "900"
      }}>
      Add Wine
    </Typography>
    <Grid
      container
      spacing={{ sm: 2, md: 4 }}
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor:"var(--color-background-admin)",
        color:"var(--color-text-dark)"
      }}
    >
      <TextField
        name="name" label="Name" variant="outlined"
        error={errors.name} helperText={errors.name}
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
      />
      <TextField 
        name="type" label="Type" variant="outlined"
        error={errors.type} helperText={errors.type}
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="cont" label="Volume" placeholder="Volume (ml)" 
        type="number" 
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            fontSize: 20,
          },
        }}
        error={errors.cont} helperText={errors.cont}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="temp" label="Temperature" placeholder="Temperature (°C)" 
        type="number"
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            fontSize: 20,
          },
        }}
        error={errors.temp} helperText={errors.temp}
        onChange={e => handleChange(e)} 
      />
      <TextField
        name="alcoholVol" label="Alcohol Volume" placeholder="Alcohol Volume" 
        type="number" 
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            fontSize: 20,
          },
        }}
        error={errors.alcoholVol} helperText={errors.alcoholVol}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="descriptionEN" label="Description (EN)" multiline maxRows={5}
        error={errors.descriptionEN} helperText={errors.descriptionEN}
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="colorEN" label="Color (EN)" multiline maxRows={5}
        error={errors.colorEN} helperText={errors.colorEN}
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="noseEN" label="Nose (EN)" multiline maxRows={5}
        error={errors.noseEN} helperText={errors.noseEN}
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="mouthEN" label="Mouth (EN)" multiline maxRows={5}
        error={errors.mouthEN} helperText={errors.mouthEN}
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="pairingEN" label="Pairing (EN)" multiline maxRows={5}
        error={errors.pairingEN} helperText={errors.pairingEN}
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="descriptionES" label="Description (ES)" multiline maxRows={5}
        error={errors.descriptionES} helperText={errors.descriptionES}
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="colorES" label="Color (ES)" multiline maxRows={5}
        error={errors.colorES} helperText={errors.colorES}
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="noseES" label="Nose (ES)" multiline maxRows={5}
        onChange={e => handleChange(e)}
        error={errors.noseES} helperText={errors.noseES}
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
      />
      <TextField
        name="mouthES" label="Mouth (ES)" multiline maxRows={5}
        error={errors.mouthES} helperText={errors.mouthES}
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="pairingES" label="Pairing (ES)" multiline maxRows={5}
        error={errors.pairingES} helperText={errors.pairingES}
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 20,
          },
        }}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="stock" label="Stock" placeholder="Stock"
        type="number"
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            fontSize: 20,
          },
        }}
        error={errors.stock} helperText={errors.stock}
        onChange={e => handleChange(e)}
      />
      <TextField
        name="price"
        label="Price"
        placeholder="Price"
        type="number"
        InputProps={{
          style: {
            fontSize: 20,
            color:"var(--color-text-dark)",
          },
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            fontSize: 20,
          },
        }}
        error={errors.price} helperText={errors.price}
        onChange={e => handleChange(e)}
      />
      <Button 
        variant="contained" component="label"
        sx={{ 
          backgroundColor: "var(--color-gray)", 
          color: "var(--color-text-dark)", 
          marginRight: "1rem", 
          padding: "1rem", 
          border: "1px solid var(--color-text-dark)",
          fontSize: 15,
        }}
        > Upload Image
        <input 
          hidden 
          name="images" 
          accept="image/*" 
          multiple type="file" 
          onInput={handleChangeImages} 
        />
      </Button>
    </Grid>
      {err ? <span> Must fill all the fields correctly </span> : null }
      <Button
        disabled= {err} 
        type="submit"
        sx={{ backgroundColor: "var(--color-gray)", marginRight: "1rem", padding: "1rem", border: "1px solid var(--color-text-dark)" }}
      >
        <Typography
          sx={{
            fontSize: { xs: "1rem", sm: "1.5rem" },
            fontFamily: "var(--font-text)",
            color: "var(--color-text-dark)",
            fontWeight: "900",
          }}>Save Wine
        </Typography>
      </Button>
    </Stack>
  </Box>
 )
}
export default CreateWine;