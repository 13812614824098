import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/Navbar/NavBar";
import Home from "./components/Home/Home";
// import Admin from "./components/Admin/Admin";
import Detail from "./components/Detail/Detail";
import Checkout from "./components/Checkout/Checkout";
import Stripe from "./components/Stripe/Stripe";
import PurchaseConfirm from "./components/PurchaseConfirmation/PurchaseConfirm";
import RouteGuard from "./components/jwt/RouterGuard";

import Experiences from "./components/Experiences/Experiences";
import Unsubscribe from "./components/PurchaseConfirmation/Unsubscribe";
import { useState } from "react";
import ScrollButton from "./components/Home/ScrollToTopButton/ScrollButton";

function App() {
  //location usada en una condicion nos va a permitir elegir en que componente renderizar
  //NavBar sin tener que copiarlo en todas las vistas
  let nav = null;
  const location = useLocation();
  const [showButton, setShowButton] = useState(false);

  window.addEventListener("scroll", () => {
    window.scrollY > 200 ? setShowButton(true) : setShowButton(false);
  });
  const allSections = document.querySelectorAll(".section_hidden");

  const revealSection = (entries, observer) => {
    const [entry] = entries;

    if (!entry.isIntersecting) return;

    entry.target.classList.remove("section_hidden");
    observer.unobserve(entry.target);
  };

  const sectionObserver = new IntersectionObserver(revealSection, {
    root: null,
    threshold: 0.15,
  });

  allSections.forEach((section) => {
    sectionObserver.observe(section);
  });

  //Lazy load images
  const imgTargets = document.querySelectorAll("img[data-src]");
  const loadImg = (entries, observer) => {
    for (const entry of entries) {
      if (!entry.isIntersecting) return;
      entry.target.src = entry.target.dataset.src;

      entry.target.addEventListener("load", () => {
        entry.target.classList.remove("lazy_img");
      });
      observer.unobserve(entry.target);
    }
  };

  const imgObserver = new IntersectionObserver(loadImg, {
    root: null,
    threshold: 0,
  });

  imgTargets.forEach((img) => imgObserver.observe(img));

  if (
    (location.pathname !== "/admin") &
    (location.pathname !== "/checkout") &
    (location.pathname !== "/confirmed/")
  ) {
    nav = <NavBar />;
  }

  return (
    <>
      {nav}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/experiences" element={<Experiences />} />
        <Route path="/admin" element={<RouteGuard />} />
        <Route path="/detail/:id" element={<Detail />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/pagos" element={<Stripe />} />
        <Route path="/confirmed" element={<PurchaseConfirm />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
      </Routes>
      {showButton && location.pathname !== "/admin" && <ScrollButton />}
    </>
  );
}

export default App;
