import { useTranslateImage } from "../../hooks/useTranslateImage";
import { Box, Typography } from "@mui/material";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import image1 from "../../images/experiences/restaurant/image1.jpg";
import image2 from "../../images/experiences/restaurant/image2.jpg";
import image3 from "../../images/experiences/restaurant/image3.jpg";
import image4 from "../../images/experiences/restaurant/image4.jpg";
import image5 from "../../images/experiences/restaurant/image5.jpg";
import image6 from "../../images/experiences/restaurant/image6.jpg";
import image7 from "../../images/experiences/restaurant/image7.jpg";
import image8 from "../../images/experiences/restaurant/image8.jpg";
import style from "./Experiences.module.css";

const images = [image1, image2, image3, image4, image5, image6, image7, image8];

export default function RestaurantOncePueblos({ t }) {
  const currentImage = useTranslateImage(images);

  return (
    <Box
      className={"section_hidden section"}
      component="section"
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { lg: "1fr 1fr 1fr" },
          rowGap: "2rem",
          columnGap: "3rem",
          backgroundColor: "var(--color-background)",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "1.4rem",
          padding: { xs: "1rem", sm: "2rem 4rem" },
          margin: { xs: "0 1rem", sm: "0 2rem", md: "0 3rem", lg: "0 2rem" },
          boxShadow: "0 1rem var(--color-blue)",
          width: { xs: "90%", sm: "100%" },
        }}
      >
        <Box
          sx={{
            gridColumn: "1/-1",
            display: { xs: "flex", sm: "grid", md: "flex" },
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "2rem", sm: "none" },
            gridTemplateColumns: "2fr 1fr",
            alignItems: "center",
            justifyContent: { md: "space-between" },
          }}
        >
          <Typography
            component="h2"
            sx={{
              color: "var(--color-blue)",
              fontSize: "3rem",
              textAlign: { xs: "center", sm: "left" },
              fontWeight: "600",
              letterSpacing: "-0.5px",
              fontFamily: "var(--font-text)",
            }}
          >
            {t("component.oncePueblos")}
          </Typography>
          <Box
            sx={{
              justifySelf: "end",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "2rem",
            }}
          >
            <a
              className={`${style.reserve_btn} ${style.phone_btn}`}
              href="tel:6461627265"
            >
              <PhoneForwardedIcon />
              {t("component.reserve")}
            </a>
            <a
              className={`${style.reserve_btn} ${style.whatsapp_btn}`}
              href="https://api.whatsapp.com/send?phone=6461627265"
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppIcon />
              WhatsApp
            </a>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "1.6rem",
              lineHeight: "1.6",
              textAlign: { lg: "justify" },
              fontFamily: "var(--font-text)",
            }}
          >
            {t("component.oncePueblosHistory")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            overflow: "hidden",
            width: { md: "50%", lg: "100%" },
            justifySelf: "center",
          }}
        >
          {images.map((image) => (
            <img
              key={image}
              className={style.restaurant_carousel_img}
              src={image}
              alt="People enjoying a tasting at Sierra Vita winery"
              style={{ transform: `translateX(${currentImage * -100}%)` }}
            />
          ))}
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "1.6rem",
              lineHeight: "1.6",
              textAlign: { lg: "justify" },
              fontFamily: "var(--font-text)",
            }}
          >
            {t("component.meetOncePueblos")}
          </Typography>
        </Box>
        <Box component="footer">
          <Typography
            sx={{
              fontSize: "1.2rem",
              display: "flex",
              flexDirection: "column",
              fontFamily: "var(--font-text)",
            }}
          >
            <em>{t("component.tastingWeekdays")}</em>
            <em>{t("component.tastingWeekends")}</em>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
