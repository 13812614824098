import React, {  useState } from "react";
import { Box, Typography, Stack, TextField, Button } from '@mui/material';
import { postNewsLetterES } from "../../Redux/actions";
import { useDispatch } from 'react-redux';

function NewsletterES() {
  
  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: "",
    news: "",
    subject: "",
  })

  const defaultValues = () => ({
    email: "",
    news: "",
    subject: "",
  });

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(postNewsLetterES(data));
      setData({
        email: "",
        news: "",
        subject: ""
      })
  };

 return(
  <Box
    direction="column"
    component="form"
    defaultValues={defaultValues}
    onSubmit={e => onSubmit(e)}
    sx={{
      '& .MuiTextField-root': { 
        m: 1, width: '40ch' 
      },
    }}
  >
    <Stack direction="column" alignItems="center" spacing={2}>
    <Typography variant="h4" component="h4" margin={2}sx={{
      fontSize: { xs: "2rem", sm: "3rem" },
      fontFamily: "var(--font-text)",
      color: "var(--color-text-dark)",
      fontWeight: "900"
      }}>
    Send Newsletter -ES
    </Typography>

    <TextField
      name="subject"
      label="Subject:"
      InputProps={{
        style: {
          fontSize: 20,
          color:"var(--color-text-dark)",
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 20,
        },
      }}
      onChange={e => handleChange(e)}
    />
    <TextField
      name="news"
      label="News:"
      multiline
      maxRows={5}
      InputProps={{
        style: {
          fontSize: 20,
          color:"var(--color-text-dark)",
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 20,
        },
      }}
      onChange={e => handleChange(e)}
    />
    <Button 
      type="submit" 
      variant="outlined"
      sx={{ backgroundColor: "var(--color-gray)", marginRight: "1rem", padding: "1rem", border: "1px solid var(--color-text-dark)" }}
      onClick={onSubmit}
    >  <Typography
    sx={{
      fontSize: { xs: "1rem", sm: "1.5rem" },
      fontFamily: "var(--font-text)",
      color: "var(--color-text-dark)",
      fontWeight: "900",
    }}>

    Send</Typography>
    </Button>
    </Stack>
  </Box>
 )
}
export default NewsletterES;