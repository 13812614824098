import { useTranslation } from "react-i18next";
import style from "./MainBanerSection.module.css";
import { LogoMobile } from "../../assets/LogoMobile";
import { Box, Button } from "@mui/material";
import video from "../../../images/winesBanner.mp4";
import { LogoName } from "../../assets/LogoName";

export default function MainBanerSection() {
  const [t] = useTranslation("global");

  const scrollToGallery = () => {
    const gallery = document.getElementById("section-gallery");
    gallery.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      id="main-baner"
      component="section"
      sx={{
        maxHeight: "90vh",
        position: "relative",
      }}
    >
      <div className={style.overlay}></div>
      <video
        src={video}
        className={style.video}
        id="background-video"
        muted
        loop
        autoPlay
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "3rem", sm: "4rem" },
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            width: { xs: "20rem", sm: "20rem", md: "30rem" },
            height: { xs: "3rem", sm: "20rem", md: "30rem" },
          }}
        >
          <div className={style.logo1}>
            <LogoMobile
              color1="#fff"
              color2="#fff"
              color3="#fff"
              width="90%"
              height="90%"
            />
          </div>
          <div className={style.logo2}>
            <div className={`${style.line} ${style.logo1Line}`}></div>
            <LogoName width="13%" height="13%" />
            <div className={style.line}></div>
          </div>
        </Box>
        <Button
          onClick={scrollToGallery}
          sx={{
            textTransform: "none",
            fontSize: { xs: "1.2rem", sm: "1.4rem" },
            fontFamily: "var(--font-text)",
            padding: "0.8rem 1.6rem",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            color: "#fff",
            borderRadius: "100rem",
            border: "2px solid currentColor",
            fontWeight: "bold",
          }}
        >
          {t("component.meetUs")}
        </Button>
      </Box>
    </Box>
  );
}
