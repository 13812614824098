import React from "react";

export const LogoMobile = ({
  color1 = "#e8e5d6",
  color2 = "#83c8cd",
  color3 = "#003d41",
  height = "50px",
  width = "40px",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1418.15 1417.47"
      height={height}
      width={width}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path
          fill={color1}
          d="M0,249c13.75,2.7,37.37,14,52,20,23.32,9.49,55.85,23.93,56,24l79,39c.23.1,61.78,32.87,62,33l41.82,24.14c6.56,4,156.18,100.86-5.66,10.93h0l-.32-.15L124,322c-.23-.12-21.75-9.91-22-10L17,279,4.12,274.45A13.91,13.91,0,0,0,0,274C-.09,274-.24,249,0,249Z"
          transform="translate(0.43)"
        />
        <polygon
          fill={color1}
          points="1327.11 0 1158.11 59 1001.11 118 802.11 209 728.11 252 603.11 315 480.11 383 332.11 476 264.11 521 207.11 561 184.11 580 319.11 502 413.11 446 546.11 370 677.11 300 849.11 222 1020.11 152 1226.11 74 1417.43 19.74 1417.43 0 1327.11 0"
        />
        <polygon
          fill={color1}
          points="0.43 595 105.43 620 222.43 658 349.43 708 436.43 745 523.43 785 595.43 821 684.43 868 779.43 925 887.43 997 1001.43 1078 971.43 1101 900.43 1046 798.43 974 730.43 933 631.43 874 559.43 832 507.43 804 436.43 769 331.43 723 224.43 681 161.43 660 80.43 635 46.43 624 0.43 612.69 0.43 595"
        />
        <path fill={color1} d="M612,1707" transform="translate(0.43)" />
      </g>
      <g id="Capa_3" data-name="Capa 3">
        <polygon
          fill={color2}
          points="1417.75 904.27 1310.43 942 1152.43 1008 1001.43 1078 832.43 1164 650.43 1268 533.43 1342 445.39 1397.48 413.92 1417.32 453.84 1417.32 688.43 1280 934.43 1157 1152.43 1059 1329.43 991 1417.75 963.24 1417.75 904.27"
        />
        <path
          fill={color2}
          d="M1325,774l-164,74L995,933,895,986l19-17,56-36,94-48,82-42,117-54,91-39,63.32-24.82S1417,732,1417,738c0,.87-11.47,4.61-28,11C1362.14,759.39,1325,774,1325,774Z"
          transform="translate(0.43)"
        />
        <polygon
          fill={color2}
          points="1417.75 553.68 1370.43 570 1204.43 639 1082.43 695 895.43 789 758.43 870 803.43 835 865.43 795 956.43 746 1062.43 690 1183.43 633 1257.43 599 1322.43 574 1370.43 555 1403.43 542 1417.75 536.27 1417.75 553.68"
        />
        <polygon
          fill={color2}
          points="1402.43 390 1261.43 442 1121.43 503 958.43 580 811.43 652 649.43 744 573.43 784 662.43 725 747.43 672 895.43 595 1023.43 530 1194.43 456 1307.43 412 1396.43 377 1417.75 371.24 1417.75 387.03 1402.43 390"
        />
        <polygon
          fill={color2}
          points="1417.43 198.94 1406.43 201 1358.43 214 1254.43 251 1125.43 302 955.43 374 840.43 432 720.43 492 593.43 559 419.43 664 394.43 679 457.43 630 510.43 595 579.43 556 638.43 519 683.43 495 743.43 463 811.43 429 886.43 393 949.43 362 1003.43 340 1057.43 314 1116.43 289 1179.43 264 1227.43 243 1289.43 220 1359.43 195 1417.43 179 1417.43 198.94"
        />
      </g>
      <g id="Capa_4" data-name="Capa 4">
        <polygon
          fill={color3}
          points="0.3 384.71 26.43 393 122.43 438 223.43 489 243.43 497 230.43 481 154.43 439 78.43 405 29.43 385 0.43 373.98 0.3 384.71"
        />
        <path
          fill={color3}
          d="M0,468.58c25.82,10.21,168.82,67,197,82.42,25.23,13.84-65.17-45.79-197-99.38C-.35,451.48-.61,468.34,0,468.58Z"
          transform="translate(0.43)"
        />
        <path
          fill={color3}
          d="M0,745.69c44.49,7.88,451.27,86.45,773,374.31,37.92,33.93-122.34-158.21-773-334.92C-.39,785-.52,745.59,0,745.69Z"
          transform="translate(0.43)"
        />
        <path
          fill={color3}
          d="M0,922c116,30.41,456,135,635,280C776.48,1316.58,479.85,1060.78,0,961-.23,961-.65,921.87,0,922Z"
          transform="translate(0.43)"
        />
        <path
          fill={color3}
          d="M510,1299S263.5,1149.52,0,1093.84c-.11,0-.45,15-.43,25.55,0,5.76.12,10.22.43,10.27C93.41,1143.64,294,1184.84,510,1299Z"
          transform="translate(0.43)"
        />
        <path
          fill={color3}
          d="M0,1306.24c31.69-1.47,117.17,9,244,63.76,287,124-133-90-244-94C-.41,1276-.65,1306.27,0,1306.24Z"
          transform="translate(0.43)"
        />
        <path
          fill={color3}
          d="M1417.32,1077C1212.49,1151.62,905,1304.83,720,1409c-4.84,2.73-10.22,5.84-14,8a2.65,2.65,0,0,0-.17.32c0,.1,8.87.1,8.94,0s.22-.32.23-.32c111.61-52.15,501.46-242.58,702.32-291.12C1417.9,1125.74,1417.8,1076.8,1417.32,1077Z"
          transform="translate(0.43)"
        />
        <path
          fill={color3}
          d="M1417,1288c-121.5,40.62-230.73,93.83-297.82,129.38-.35.19-9.8,0-9.69-.06a65.12,65.12,0,0,1,7-4.53c62.78-38.89,167.1-99.22,300.46-160.79C1417.85,1251.61,1417.82,1287.72,1417,1288Z"
          transform="translate(0.43)"
        />
        <path fill={color3} d="M-1.36,753.71" transform="translate(0.43)" />
      </g>
    </svg>
  );
};
