import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import process from "../../../images/process.jpg";
import process_small from "../../../images/process_small.jpg";
import style from "./Process.module.css";

export default function Process() {
  const [t] = useTranslation("global");

  return (
    <Box
      component="section"
      sx={{
        paddingTop: { lg: "4rem" },
        paddingBottom: { lg: "9.6rem" },
        backgroundColor: "var(--color-background)",
      }}
    >
      <Grid
        container
        className="section_hidden section"
        spacing={{ sm: 2, md: 4 }}
        alignItems="center"
        justifyContent="center"
        flexDirection={{ md: "row-reverse" }}
      >
        <Grid item xs={12} md={6} sx={{ padding: { xs: "2rem", sm: 0 } }}>
          <img
            className={`${style.process_img} lazy_img`}
            src={process_small}
            data-src={process}
            alt="Vineyard worker"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            variant="h2"
            component="h2"
            gutterBottom
            sx={{
              padding: "0 3.5rem",
              color: "var(--color-blue)",
              fontFamily: "var(--font-title)",
            }}
          >
            {t("component.process")}
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "1.8rem",
                md: "2rem",
              },
              lineHeight: 1.7,
              padding: "0 3.5rem 5rem",
              color: "var(--color-text-light)",
              fontFamily: "var(--font-text)",
            }}
          >
            {t("component.processText")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
