import React from "react";
import { Box, Rating } from "@mui/material";
import { FacebookRounded, Instagram, Star} from "@mui/icons-material";
import { Typography } from "@mui/material";
import vivino from "../../../images/ratingVivino.png"
import g from "../../../images/ratingG.png"
import tripadvisor from "../../../images/ratingTripadvisor.png"
import bruxelles from "../../../images/bruxellesPrize.png"
import bruxelles2 from "../../../images/bruxellesPrize2.png"
import style from "./Footer.module.css";

export default function Footer() {

  const vivinoRank = 3.8
  const googleRank = 4.7
  const tripadvisorRank = 4.5

  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{
        display: "flex",
        flexWrap: 'wrap',
        justifyContent: "space-between",
        alignItems: "center",
        padding: { xs: "1rem", md: "1rem 3rem" },
        backgroundColor: "rgb(20, 20, 20)",
      }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1.2rem',
          }}
        >
          <a
            className={style.social_link}
            href="https://www.vivino.com/wineries/sierra-vita"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={vivino}
              data-src={vivino}
              width={95}
              alt="vivino"
            />
          </a>
          <Rating
            name="vivino"
            value={vivinoRank}
            readOnly
            precision={0.5}
            sx={{
              marginTop: "2.5rem",
            }}
            emptyIcon={<Star style={{ opacity: 0.35, color: "#DCDCDC" }} fontSize="inherit" />}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: "1rem",
          }}
        >
          <a
            className={style.social_link}
            href="https://goo.gl/maps/KiF9t4fuFdpNinaK8"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={g}
              data-src={g}
              width={50}
              alt="google"
            />
          </a>
          
          <Rating
            name="g"
            value={googleRank}
            readOnly
            precision={0.5}
            sx={{
              marginTop: "1rem",
            }}
            emptyIcon={<Star style={{ opacity: 0.35, color: "#DCDCDC" }} fontSize="inherit" />}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: "1rem",
          }}
        >
          <a
            className={style.social_link}
            href="https://www.tripadvisor.in/Restaurant_Review-g499403-d13544138-Reviews-Once_Pueblos-Valle_de_Guadalupe_Ensenada_Municipality_Baja_California.html"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={tripadvisor}
              data-src={tripadvisor}
              width={60}
              alt="tripadvisor"
            />
          </a>
          <Rating
            name="tripadvisor"
            value={tripadvisorRank}
            readOnly
            precision={0.5}
            sx={{
              margin: "0.6rem",
            }}
            emptyIcon={<Star style={{ opacity: 0.35, color: "#DCDCDC" }} fontSize="inherit" />}
          />
        </Box>
        
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: "1rem",
          }}
        >
          <a
            className={style.social_link}
            href="https://concoursmondial.com/fr/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={bruxelles}
              data-src={bruxelles}
              width={85}
              alt="bruxeles festival"
            />
          </a>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: "1rem",
          }}
        >
          <a
            className={style.social_link}
            href="https://concoursmondial.com/fr/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={bruxelles2}
              data-src={bruxelles2}
              width={95}
              alt="bruxeles festival"
            />
          </a>
        </Box>
      </Box>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: { xs: "1rem", md: "1rem 3rem" },
        backgroundColor: "rgb(65, 64, 64)",
        color: "var(--color-text-light)",
      }}>
      <Box>
        <Typography sx={{ 
          fontSize: "1.6rem", 
          fontFamily: "var(--font-text)", 
        }}>
          Valle de Guadalupe, Ensenada, México
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "1.2rem" }}>
        <a
          className={style.social_link}
          href="https://www.facebook.com/sierravita/"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookRounded sx={{ 
            fontSize: "2.4rem",
            fontFamily: "var(--font-text)", 
          }} />
        </a>
        <a
          className={style.social_link}
          href="https://www.instagram.com/sierravitawinery/?hl=es"
          target="_blank"
          rel="noreferrer"
        >
          <Instagram sx={{ fontSize: "2.4rem" }} />
        </a>
      </Box>
      </Box>
    </Box>
  );
}
