import React from 'react'
import { Box, ListItem, ListItemText } from '@mui/material'
import style from './OrderProducts.module.css'
import { useTranslation } from 'react-i18next';

const OrderProducts = ({ id, name, img, price, quantity }) => {

  const [t] = useTranslation("global");

  return (
    <Box className={style.item_list} sx={{ padding: { xs: '10px', sm: '10px' } }} >
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} >
        <div className={style.image_box} >
          <img src={`https://${img}`} alt={name} />
        </div>
        <ListItem sx={{ display: 'block', paddingTop: '0px' }}>
          <ListItemText primary={name} className={style.listItemName} />
          <Box sx={{ display: { xs: 'flex' }, alignItems: { xs: 'center' }, justifyContent: 'center' }} >
            <ListItemText sx={{ display: 'flex', flexGrow: '0.2' }} className={style.listItemPrice} >${price}</ListItemText>
            <ListItemText sx={{ display: 'flex', flexGrow: '0.2' }} className={style.listItemPrice} >{t("component.quantity")}: {quantity}</ListItemText>
          </Box>
        </ListItem>
      </Box>
    </Box>
  )
}

export default OrderProducts