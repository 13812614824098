import * as React from 'react';
import Switch from '@mui/material/Switch';
import { useTranslation } from "react-i18next"
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { switchLanguage } from '../Redux/actions';
import style from "./SwitchLanguage.module.css"

export default function SwitchLanguage() {

  const [, i18n] = useTranslation("global")
  //es importante que los json usados ↑ tengan el mismo 
  //nombre y estructura en cada idioma
  
  const switchChecked = useSelector(state => state.languageSelect)
  const dispatch = useDispatch()
  const [checked, setChecked] = React.useState(true);

  const handleChange = () => {
   dispatch(switchLanguage())
   setChecked(!checked)
  };
  
  React.useEffect(()=>{
    checked ? i18n.changeLanguage("en") : i18n.changeLanguage("es") 
    setChecked(switchChecked)
  }, [checked])

  return (
    <div className={style.switch} >
      <Typography sx={{ 
        fontSize: "1.2rem", 
        fontFamily: "var(--font-text)", 
      }}>ES</Typography>
      <Switch
        color="default" 
        checked={switchChecked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        />
      <Typography sx={{ 
        fontSize: "1.2rem", 
        fontFamily: "var(--font-text)", 
      }}>EN</Typography>
    </div>
  );
}
