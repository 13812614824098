import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch, useSelector } from "react-redux";
import { Box, Drawer, Divider, IconButton, Button, Stack, Switch, TextField, Typography } from '@mui/material';
import { updateAdmin } from '../../Redux/actions';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 1),
  backgroundColor: '#121212',
  width: '400px',
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

function EditAdminForm({ openDrawer, closeDrawer }) {
  const theme = useTheme();
  const InfoAdmin = useSelector(state => state.adminDetail)
  const dispatch = useDispatch();

  const [input, setInput] = React.useState({})
  const [active, setActive] = React.useState(InfoAdmin.isActive)

  React.useEffect(() => {
    if (InfoAdmin) {
      setActive(InfoAdmin.isActive)
      setInput(
        {
          name: InfoAdmin.name,
          lastname: InfoAdmin.lastname,
          username: InfoAdmin.username,
          email: InfoAdmin.email,
          // password: InfoAdmin.password,
          isActive: active || InfoAdmin.isActive,
        }
      )
    }
    return () => {
      setInput({
        name: "",
        lastname: "",
        username: "",
        email: "",
        // password:"",
      })
    }
  }, [InfoAdmin, closeDrawer])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    })
  }

  const handleSwitch = () => {
    setActive(!active)
    setInput({
      ...input,
      isActive: !active
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(updateAdmin(InfoAdmin._id, input))
    closeDrawer(false)
  }

  return (
    <>
      <Box sx={{ display: 'flex' }} >
        <Drawer
          sx={{
            flexShrink: 0
          }}
          anchor="right"
          open={openDrawer}
          onClose={() => closeDrawer(false)}
        >
          <DrawerHeader>
            <IconButton onClick={() => closeDrawer(false)} >
              {theme.direction === 'ltr' ? <ChevronRightIcon sx={{ color: '#fefdfd' }} /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider sx={{ backgroundColor: '#fefdfd' }} />
          <Box
            direction="column"
            component="form"
            onSubmit={e => handleSubmit(e)}
            sx={{
              '& .MuiTextField-root': { m: 1, width: '50ch' },
            }}
          >
            <Stack direction="column" alignItems="center" spacing={2}>
              <Typography variant="h4" component="h4" margin={2} sx={{
                fontSize: { xs: "2rem", sm: "3rem" },
                fontFamily: "var(--font-text)",
                color: "var(--color-text-dark)",
                fontWeight: "900"
              }}>
                Edit Admin
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={!active ? { color: 'red', fontSize: '15px', fontWeight: 'bold' } : { color: 'gray', fontSize: '15px' }}>Disabled</Typography>
                <Switch
                  checked={active}
                  onChange={() => handleSwitch()}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography
                  sx={active ? { color: 'green', fontSize: '15px', fontWeight: 'bold' } : { color: 'gray', fontSize: '15px' }}
                >Active</Typography>
              </Box>
              <TextField
                name="name"
                label="Name"
                InputProps={{
                  style: {
                    fontSize: 20,
                    color: "var(--color-text-dark)",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontSize: 20,
                  },
                }}
                onChange={e => handleChange(e)}
                value={input.name}
              />
              <TextField
                name="lastname"
                label="Lastname"
                InputProps={{
                  style: {
                    fontSize: 20,
                    color: "var(--color-text-dark)",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontSize: 20,
                  },
                }}
                onChange={e => handleChange(e)}
                value={input.lastname}
              />
              <TextField
                name="username"
                label="Username"
                InputProps={{
                  style: {
                    fontSize: 20,
                    color: "var(--color-text-dark)",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontSize: 20,
                  },
                }}
                onChange={e => handleChange(e)}
                value={input.username}
              />
              <TextField
                name="email"
                label="Email"
                InputProps={{
                  style: {
                    fontSize: 20,
                    color: "var(--color-text-dark)",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontSize: 20,
                  },
                }}
                onChange={e => handleChange(e)}
                value={input.email}
              />
              {/* <TextField
              name="password"
              label="Password"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => handleChange(e)}
              value={input.password}
            /> */}
              <Button
                type="submit"
                variant="outlined" sx={{ backgroundColor: "var(--color-gray)", marginRight: "1rem", padding: "1rem", border: "1px solid var(--color-text-dark)" }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "1rem", sm: "1.5rem" },
                    fontFamily: "var(--font-text)",
                    color: "var(--color-text-dark)",
                    fontWeight: "900",
                  }}>Save Admin
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Drawer>
      </Box>
    </>
  )
}

export default EditAdminForm