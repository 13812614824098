import React from 'react'
import { Box, Divider, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { ColorRing } from 'react-loader-spinner';
import style from '../Checkout.module.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const PaymentCheck = ({ totalPrice, setView, shipmentInformation }) => {

  const stripe = useStripe()
  const elements = useElements()
  const ShipmentInformation = useSelector(state => state.shipmentInformation)
  const WinesCart = useSelector(state => state.itemsShoppingCart)
  const [totalPriceCheckout, setTotalPriceCheckout] = React.useState(0)
  //const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const standardDelivery = 350.00;
  const [t] = useTranslation("global");

  React.useEffect(() => {
    const OrderInformation = {
      shipmentInformation: ShipmentInformation,
      products: WinesCart,
      totalPrice: totalPrice + standardDelivery,
    }
    localStorage.setItem("info", JSON.stringify(OrderInformation))
  }, [ShipmentInformation])

  React.useEffect(() => {


    PriceTotal()

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const PriceTotal = () => {
    setTotalPriceCheckout(totalPrice + standardDelivery)
  }

  /* const onchange = (e) => {
    //setEmail()
  } */

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://sierravita.com/confirmed/", //aqui ira el componente que ordena el descuento de productos
        receipt_email: shipmentInformation.email,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <button onClick={() => setView(true)} >
        <ChevronLeftIcon />
        {t("component.back")}
      </button>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} className={style.box_container}>
        <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
          {/*-------INFORMACION DEL PAGO-------------*/}
          <Box className={style.box} >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#61a6ab', marginLeft: '10px', fontSize: '1.5rem' }}>{t("component.cardInformation")}</Typography>
              <span>{t("component.required")}</span>
            </Box>
            <Divider sx={{ background: '#fefdfd' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column' }} className={style.box_labels} >
              {/* <label>Email del usuario</label> */}
              {/*  <LinkAuthenticationElement
                onChange={(e) => onchange(e)}
              /> */}
              <PaymentElement id="payment-element" />
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
          {/*--------RESUMEN DE COSTOS----------------------*/}
          <Box className={style.box} >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#61a6ab', marginLeft: '10px', fontSize: '1.5rem' }}>{t("component.purchaseSummary")}</Typography>
            </Box>
            <Divider sx={{ background: '#fefdfd' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ marginTop: '5px', marginLeft: '5px', color: '#fefdfd', fontSize: '1.6rem' }}>
                Subtotal: ${totalPrice}
              </Typography>
              <Typography sx={{ marginTop: '5px', marginLeft: '5px', color: '#fefdfd', fontSize: '1.6rem' }}>
                {t("component.standardDelivery")}: ${standardDelivery}
              </Typography>
              <Divider sx={{ background: '#fefdfd' }} />
              <Typography sx={{ marginTop: '5px', marginLeft: '5px', color: '#fefdfd', fontSize: '1.6rem' }}>
                Total: ${totalPriceCheckout}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} className={style.box_labels} >
              <label style={{ color: '#61a6ab' }}>{t("component.discountCode")}</label>
              <input type="text" style={{ fontSize: '2rem' }} />
            </Box>
            <Box>
              <button className={style.button_desc} >{t("component.applyDiscountCode")}</button>
            </Box>
          </Box>
          {/*-------FINALIZAR COMPRA----------------------*/}
          <Box sx={{ margin: '15px', textAlign: 'center' }}>
            <button disabled={isLoading || !stripe || !elements} className={style.button_final} >
              {isLoading ? <ColorRing
                visible={true}
                height="60"
                width="60"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#61a6ab', '#61a6ab', '#61a6ab', '#61a6ab', '#61a6ab']}
              /> :
                t("component.finalizePurchase")}
            </button>
          </Box>
          {message && <div className={style.payment_message}>{message}</div>}
        </Box>
      </Box >
    </form>
  )
}

export default PaymentCheck;