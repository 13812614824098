import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, FormControl, InputLabel, ListItemAvatar, MenuItem, Select  } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAdminDetail, getAllAdmins } from '../../Redux/actions';
import EditAdminForm from './EditAdminForm';
import style from '../EditWine/Edit.module.css'
import BorderColorIcon from '@mui/icons-material/BorderColor';

function EditAdmin() {
  const [data, setData] = React.useState([]);
  const [drawerEdit, setDrawerEdit] = React.useState(false);
  const [selectStateFilter, setSelectStateFilter] = React.useState('');

  const AllAdmins = useSelector(state => state.AllAdmins);
  const dispatch = useDispatch();

  const handleChangeSelectFilter = (e) => {
    setSelectStateFilter(e.target.value)
    if (e.target.value === "active") {
      let admins =  AllAdmins.filter((admins) => { return admins.isActive === true })
      return setData(admins)
    } else if (e.target.value === "noActive") {
      let admins = AllAdmins.filter((admins) => { return admins.isActive === false })
      if (admins.length === 0) {
        return setData(null)
      } else setData(admins)
    } else return setData(AllAdmins)
  }

  const editAdmin = (id) => {
    dispatch(getAdminDetail(id))
    setDrawerEdit(true)
  }

  React.useEffect(() => {
    dispatch(getAllAdmins())
    setData(AllAdmins)
  }, [AllAdmins.length])

  return (
    <>
      <EditAdminForm openDrawer={drawerEdit} closeDrawer={setDrawerEdit} />
      <Box className={style.box_container} >
        <Box>
        <FormControl sx={{ m: 1, minWidth: 150, marginBottom: '30px' }}>
            <InputLabel id="demo-simple-select-label" sx={{ color: '#000', fontSize: '1.6rem' }}>Filter</InputLabel>
            <Select
              sx={{ color: '#000', fontSize: '1.6rem' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectStateFilter}
              label="filter"
              onChange={(e) => { handleChangeSelectFilter(e) }}
            >
              <MenuItem value='all' sx={{ color: '#000', fontSize: '1.6rem' }}>All</MenuItem>
              <MenuItem value='active' sx={{ color: '#000', fontSize: '1.6rem' }}>Active</MenuItem>
              <MenuItem value='noActive' sx={{ color: '#000', fontSize: '1.6rem' }}>No Active</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <TableContainer component={Paper} className={style.table_container} >
          <Table aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Name</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Lastname</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Username</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Email</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Active</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Edit</TableCell>
                {/* <TableCell sx={{ fontSize: '1.6rem' }} >Password</TableCell> */}
              </TableRow>
            </TableHead>
            {data === null ? <h1>Not admins added </h1> : data.length < 1 ? <h1>Loading</h1> :
              <TableBody>
                {data.map((admin) => (
                  <TableRow
                    key={admin._id}
                    className={style.table_row}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{admin.name}</TableCell>
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{admin.lastname}</TableCell>
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{admin.username}</TableCell>
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{admin.email}</TableCell>
                    {admin.isActive ?
                      <TableCell sx={{ fontSize: '1.5rem', color: 'green', fontWeight: '600' }} align="center">Active</TableCell>
                      :
                      <TableCell sx={{ fontSize: '1.5rem', color: 'red' }} align="center">No Active</TableCell>
                    }
                    <TableCell align="center">
                      <BorderColorIcon onClick={() => editAdmin(admin._id)} sx={{ color: '#61a6ab', cursor: 'pointer' }} />
                    </TableCell> 
                    {/* <TableCell sx={{ fontSize: '1.5rem' }} >{admin.password}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            }
          </Table>
        </TableContainer >
      </Box>
    </>
  )
}

export default EditAdmin