import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import purchase_confirm from "../../images/purchase_confirm.png";
import style from "../Detail/Detail.module.css";
import { URL } from "../Redux/actions";

function PurchaseConfirm() {
  const [t] = useTranslation("global");

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("info"));
    if (data) {
      fetch(`${URL}/payment/create-order`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data,
        }),
      }).then((res) => console.log(res.json()));
    }
    return () => {
      localStorage.removeItem("info");
    };
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      padding="5rem"
      marginTop="5rem"
    >
      <Grid item xs={12}>
        <img
          src={purchase_confirm}
          alt={"purchase_confirm"}
          style={{ width: 150 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="h1"
          gutterBottom
          sx={{
            fontSize: "3.5rem",
            color: "var(--color-blue)",
            fontFamily: "var(--font-text)",
          }}
        >
          {t("component.purchaseComplete")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Link to="/">
          <button className={style.wine_btn}>
            {t("component.continueShopping")}
          </button>
        </Link>
      </Grid>
    </Grid>
  );
}

export default PurchaseConfirm;
