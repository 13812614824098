import React from 'react'
import { Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemText, Stack, Switch, TextField, Typography } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrder } from '../../Redux/actions';
import style from './Orders.module.css';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 1),
  backgroundColor: '#121212',
  width: '400px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const OrderExpanded = ({ openDrawer, closeDrawer, id }) => {

  const theme = useTheme();

  const AllOrders = useSelector(state => state.adminOrders)
  const [active, setActive] = React.useState(true)
  const [orderDetail, setOrderdetail] = React.useState([]);
  const [input, setInput] = React.useState({ observations: '', isSent: active });
  const dispatch = useDispatch()

  React.useEffect(() => {
    const order = AllOrders.filter(order => order._id === id)
    if (order.length) {
      setOrderdetail(order)
      setActive(order[0].isSent)
    }
    return () => { setOrderdetail({}) }
  }, [id])


  const handleSwitch = () => {
    setActive(!active)
    setInput({
      ...input,
      isSent: !active
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(updateOrder(id, input))
    closeDrawer(false)
  }

  return (
    <Box sx={{ display: 'flex' }} >
      <Drawer
        sx={{
          flexShrink: 0
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => closeDrawer(false)}
      >
        <DrawerHeader>
          <IconButton onClick={() => closeDrawer(false)} >
            {theme.direction === 'ltr' ? <ChevronRightIcon sx={{ color: '#fefdfd' }} /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ backgroundColor: '#fefdfd' }} />
        <Box
          direction="column"
          component="form"
          onSubmit={e => handleSubmit(e)}
          sx={{
            '& .MuiTextField-root': { m: 1, width: '50ch' }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography sx={!active ? { color: 'red', fontSize: '12px', fontWeight: 'bold' } : { color: 'gray', fontSize: '10px' }}>Not Sent</Typography>
            <Switch
              checked={active}
              onChange={() => handleSwitch()}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography
              sx={active ? { color: 'green', fontSize: '12px', fontWeight: 'bold' } : { color: 'gray', fontSize: '10px' }}
            >Sent</Typography>
          </Box>
          <Box className={style.information_box} >
            <h3 className={style.title_info} >Buyer Information</h3>
            {orderDetail.length &&
              orderDetail.map(order =>
                <Box key={order._id}>
                  <ul className={style.ul_info}>
                    <li>Name: <Typography sx={{ fontSize: '15px' }}>{order.name}</Typography></li>
                    <li>Email: <Typography sx={{ fontSize: '15px' }}>{order.email}</Typography></li>
                    <li>Purchase value: <Typography sx={{ fontSize: '15px' }}>${order.amount}</Typography></li>
                    <li>Phone: <Typography sx={{ fontSize: '15px' }}>{order.phone}</Typography></li>
                    <li>Shipping Address: <Typography sx={{ fontSize: '15px' }}>{order.adress1}</Typography></li>
                    <li>City: <Typography sx={{ fontSize: '15px' }}>{order.city}</Typography></li>
                    <li>State: <Typography sx={{ fontSize: '15px' }}>{order.state}</Typography></li>
                    <li>Postal Code: <Typography sx={{ fontSize: '15px' }}>{order.name}</Typography></li>
                    <li>Key RFC: <Typography sx={{ fontSize: '15px' }}>{order.RFC}</Typography></li>
                    <li>Costumer's comments: <Typography sx={{ fontSize: '15px' }}>{order.description}</Typography></li>
                  </ul>
                  <Divider />
                  <h3 className={style.title_info} >Products in order:</h3>
                  <ol className={style.ol_info} >
                    {order.order.map(order =>
                      <li className={style.li_info}>
                        Name:<Typography sx={{ fontSize: '15px', margin: '0px 3px' }}>{order.name}</Typography>  /
                        Price:<Typography sx={{ fontSize: '15px', margin: '0px 3px' }}>${order.price} </Typography> /
                        Cuantity:<Typography sx={{ fontSize: '15px', margin: '0px 3px' }}>{order.quantity}</Typography>
                      </li>
                    )}
                  </ol>
                  <Divider />
                  {/* <Box sx={{ textAlign: 'center' }}>
                    <TextField
                      name="observations"
                      label="Observations"
                      multiline
                      maxRows={5}
                      onChange={e => handleChange(e)}
                      sx={{ textAlign: 'center' }}
                    />
                  </Box> */}
                  <Box sx={{ textAlign: 'center' }}>
                    <Button
                      type="submit"
                      variant="outlined">
                      Save
                    </Button>
                  </Box>
                </Box>
              )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  )
}

export default OrderExpanded