import React, { useState, useEffect } from "react";
import Login from "./Login";
import Admin from "../Admin/Admin";

const RouteGuard = ({ component: Component, ...rest }) => {

	const [token, setToken] = useState('');
	// let jwt = useSelector((state) => state.jwt);

	useEffect(() => {
		if(!token || token.length < 0) {
			setToken(sessionStorage.getItem("token"))
		}
	}, [token])


  return (
		<>
		{token ? <Admin /> : <Login /> }
		</>
  );
};

export default RouteGuard;
