import React from "react";
import { Grid } from '@mui/material';
import CreateAdmin from "./CreateAdmin";
import EditAdmin from "./EditAdmin";
import Stripelink from "./Stripelink"

function Admins() {
  return (
    <Grid
      container
      spacing={{ sm: 2, md: 4 }}
      alignItems="center"
      justifyContent="center"
      sx={{backgroundColor:"var(--color-background-admin)"}}
    >
      <Grid item xs={12} sm={6} md={6} >
        <CreateAdmin />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Stripelink />
        </Grid> 
        <Grid item xs={12} sm={12} md={12}>
        <EditAdmin />
        
      </Grid> 
    </Grid>
  )
}
export default Admins;