const initialState = {
  wines: [],
  itemsShoppingCart: [],
  lastWineAddedToCart: {},
  totalItemsCart: 0,
  totalPriceProducts: 0,
  languageSelect: true,
  openShoppingCart: false,
  wineDetail: [],
  shipmentInformation: {},
  jwt: "",
  AllProductsInDB: [],
  wineDetailInfoAdmin: [],
  navScroll: '',
  AllAdmins: [],
  adminDetail: [],
  adminOrders: [],
  AllSubs: []
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_WINES":
      return {
        ...state,
        wines: action.payload,
      };

    case "ADD_ITEMS_STORAGE":
      let searchItem = state.itemsShoppingCart.some(
        (item) => item._id === action.payload._id
      );
      if (!searchItem) {
        action.payload.quantity = 1;
        return {
          ...state,
          itemsShoppingCart: [...state.itemsShoppingCart, action.payload],
          lastWineAddedToCart: action.payload,
        };
      } else return { ...state };

    case "EMPTY_WINE_ADDED_TO_CART":
      return {
        ...state,
        lastWineAddedToCart: {},
      };

    case "NO_STOCK_AVAILABLE":
      return {
        ...state,
        lastWineAddedToCart: action.payload,
      };

    case "GET_TOTAL_ITEMS":
      let totalItems = state.itemsShoppingCart.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
      return {
        ...state,
        totalItemsCart: totalItems,
      };

    case "GET_TOTAL_PRICE":
      let priceArray = [];
      state.itemsShoppingCart.map((i) => {
        priceArray.push(i.price * i.quantity);
      });
      const priceTotal = priceArray.reduce((acc, curr) => acc + curr, 0);
      return {
        ...state,
        totalPriceProducts: parseFloat(priceTotal.toString().substring(0, 5)),
      };

    case "SUBTRACT_QUANTITY_ITEMS":
      let item = state.itemsShoppingCart.find(
        (el) => el._id === action.payload
      );
      let index = state.itemsShoppingCart.findIndex(
        (el) => el._id === action.payload
      );
      let itemFilter = state.itemsShoppingCart.filter(
        (el) => el._id !== action.payload
      );
      if (item !== undefined) {
        item.quantity -= 1;
        itemFilter.splice(index, 0, item);
      }
      return {
        ...state,
        itemsShoppingCart: itemFilter,
      };

    case "ADD_QUANTITY_ITEMS":
      let itemSearch = state.itemsShoppingCart.find(
        (el) => el._id === action.payload
      );
      let indexItem = state.itemsShoppingCart.findIndex(
        (el) => el._id === action.payload
      );
      let itemsFilter = state.itemsShoppingCart.filter(
        (el) => el._id !== action.payload
      );
      if (itemSearch !== undefined) {
        itemSearch.quantity += 1;
        itemsFilter.splice(indexItem, 0, itemSearch);
      }
      return {
        ...state,
        itemsShoppingCart: itemsFilter,
      };

    case "DELETE_ITEM_SHOPPING_CART":
      let itemsCart = state.itemsShoppingCart.filter(
        (it) => it._id !== action.payload
      );
      return {
        ...state,
        itemsShoppingCart: itemsCart,
      };

    case "SWITCH_LANGUAGE":
      return {
        ...state,
        languageSelect: !state.languageSelect,
      };

    case "OPEN_CART_STATE":
      return {
        ...state,
        openShoppingCart: !state.openShoppingCart,
      };

    case "POST_WINE":
      return {
        ...state,
      };

    case "GET_ALL_WINES_ADMIN":
      return {
        ...state,
        AllProductsInDB: action.payload,
      };

    case "GET_WINE_DETAIL_INFO_ADMIN":
      return {
        ...state,
        wineDetailInfoAdmin: action.payload,
      };

    case "GET_WINE_DETAIL":
      return {
        ...state,
        wineDetail: action.payload,
      };

    case "SHIPMENT_INFORMATION":
      return {
        ...state,
        shipmentInformation: action.payload,
      };

    case "SET_JWT":
      return {
        ...state,
        jwt: action.payload,
      };

    case "NAV_SCROLL":
      return {
        ...state,
        navScroll: action.payload
      };

    case "GET_ALL_ADMINS":
      return {
        ...state,
        AllAdmins: action.payload,
      };

    case "GET_ADMIN_DETAIL":
      return {
        ...state,
        adminDetail: action.payload,
      };

    case "GET_ORDERS":
      return {
        ...state,
        adminOrders: action.payload
      }

    case "GET_ALL_SUBS":
      return {
        ...state,
        AllSubs: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default rootReducer;
