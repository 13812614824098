import React from 'react'
import CheckStripe from './CheckStrip'
import ShippingForm from './CheckoutForms/ShippingForm'
import style from './Checkout.module.css'

const Checkout = () => {

  const [view, setView] = React.useState(true)

  React.useEffect(() => {
  }, [])

  return (
    <div className={style.check_container}>
      {view ? <ShippingForm setView={setView} /> : <CheckStripe setView={setView} />}
    </div>
  )
}

export default Checkout;