import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, Typography, InputLabel, Select, MenuItem, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { getAllSubs } from '../../Redux/actions';
import style from '../EditWine/Edit.module.css'

export default function SubsList() {
  const dispatch = useDispatch();
  const AllSubs = useSelector(state => state.AllSubs);

  const [data, setData] = React.useState([]);
  const [selectStateFilter, setSelectStateFilter] = React.useState('');

  React.useEffect(() => {
    dispatch(getAllSubs())
    setData(AllSubs)
  }, [AllSubs.length])

  const handleChangeSelectFilter = (e) => {
    setSelectStateFilter(e.target.value)
    if (e.target.value === "active") {
      let subs =  AllSubs.filter((subs) => { return subs.isActive === true })
      if (subs.length === 0) {
        return setData(null)
      } else setData(subs)

    } else if (e.target.value === "noActive") {
      let subs = AllSubs.filter((subs) => { return subs.isActive === false })
      if (subs.length === 0) {
        return setData(null)
      } else setData(subs)

    } else if (e.target.value === "english") {
      let subs =  AllSubs.filter((subs) => { return subs.inEnglish === true })
      if (subs.length === 0) {
        return setData(null)
      } else setData(subs)
      
    } else if (e.target.value === "spanish") {
      let subs = AllSubs.filter((subs) => { return subs.inEnglish === false })
      if (subs.length === 0) {
        return setData(null)
      } else setData(subs)

    } else {
      
      return setData(AllSubs)
    }
  }

  
  return (
    <Box className={style.box_container}>
      <Box>
        <FormControl sx={{ m: 1, minWidth: 150, marginBottom: '30px' }}>
          <InputLabel id="demo-simple-select-label" sx={{ color: '#000', fontSize: '1.6rem' }}>Filter</InputLabel>
          <Select
            sx={{ color: '#000', fontSize: '1.6rem' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectStateFilter}
            label="filter"
            onChange={(e) => { handleChangeSelectFilter(e) }}
          >
            <MenuItem value='all' sx={{ color: '#000', fontSize: '1.6rem' }}>All</MenuItem>
            <MenuItem value='active' sx={{ color: '#000', fontSize: '1.6rem' }}>Active</MenuItem>
            <MenuItem value='noActive' sx={{ color: '#000', fontSize: '1.6rem' }}>No Active</MenuItem>
            <MenuItem value='english' sx={{ color: '#000', fontSize: '1.6rem' }}>English</MenuItem>
            <MenuItem value='spanish' sx={{ color: '#000', fontSize: '1.6rem' }}>Spanish</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="h4" component="h4" margin={2}sx={{
          fontSize: { xs: "0.5rem", sm: "2rem" },
          fontFamily: "var(--font-text)",
          color: "var(--color-text-dark)",
          fontWeight: "900"
          }}> Subs: {data.length} 
        </Typography>
      </Box>
    <TableContainer component={Paper} className={style.table_container}>
          <Table aria-label="a dense table">
            <TableHead>
              <TableRow> 
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Email</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Name</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Lastname</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Phone</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Language</TableCell> 
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Active</TableCell>
              </TableRow>
            </TableHead>
            {data === null ? <h1>Not subscribers added </h1> : data.length < 1 ? <h1>Loading...</h1> :
              <TableBody>
                {data.map((sub) => (
                  <TableRow
                    key={sub._id}
                    className={style.table_row}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{sub.email}</TableCell>
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{sub.name}</TableCell>
                    
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{sub.lastname}</TableCell>
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{sub.phone}</TableCell>
                    {sub.inEnglish ?
                      <TableCell sx={{ fontSize: '1.5rem'}} align="center">English</TableCell>
                      :
                      <TableCell sx={{ fontSize: '1.5rem'}} align="center">Spanish</TableCell>
                    }        
                    {sub.isActive ?
                      <TableCell sx={{ fontSize: '1.5rem', color: 'green', fontWeight: '600' }} align="center">Active</TableCell>
                      :
                      <TableCell sx={{ fontSize: '1.5rem', color: 'red' }} align="center">No Active</TableCell>
                    }
                  </TableRow>
                ))}
              </TableBody>
            }
          </Table>
        </TableContainer >
    </Box>
  )
}