import React from "react";
import { useDispatch } from "react-redux";
import { Box, ListItem, ListItemText } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  addQuantityItems,
  deleteItemToCart,
  getTotalItems,
  getTotalPrice,
  subtractQuantityItems,
} from "../../Redux/actions";
import style from "./ProductItem.module.css";

const ProductItem = ({ name, id, img, price, quantity }) => {
  let stock = 10;
  let dispatch = useDispatch();

  const addItemProducts = () => {
    quantity < stock && dispatch(addQuantityItems(id));
  };

  const subsItemProducts = () => {
    quantity > 1 && dispatch(subtractQuantityItems(id));
  };

  React.useEffect(() => {
    dispatch(getTotalItems());
    dispatch(getTotalPrice());
  }, [quantity]);

  return (
    <Box
      className={style.item_list}
      sx={{ padding: { xs: "10px", sm: "10px" } }}
    >
      <div className={style.delete_box}>
        <button
          className={style.delete_button}
          onClick={() => {
            dispatch(deleteItemToCart(id));
            dispatch(getTotalItems());
            dispatch(getTotalPrice());
          }}
        >
          <DeleteForeverIcon sx={{ height: "20px", width: "20px" }} />
        </button>
      </div>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
        <div className={style.image_box}>
          <img src={`https://${img}`} alt={name} />
        </div>
        <ListItem sx={{ display: "block", paddingTop: "0px" }}>
          <ListItemText primary={name} className={style.listItemName} />
          <Box
            sx={{
              display: { xs: "flex" },
              alignItems: { xs: "center" },
              justifyContent: "center",
            }}
          >
            <ListItemText
              sx={{ display: "flex", flexGrow: "0.2" }}
              className={style.listItemPrice}
            >
              ${price}
            </ListItemText>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fefdfd",
              }}
              className={style.counter_button_box}
            >
              <button
                className={style.button_counter}
                onClick={() => {
                  subsItemProducts();
                }}
                sx={{ background: "#61a6ab", height: "20px", width: "20px" }}
              >
                -
              </button>
              <ListItemText primary={quantity} sx={{ margin: "0px 6px" }} />
              <button
                className={style.button_counter}
                onClick={() => {
                  addItemProducts();
                }}
                sx={{ background: "#61a6ab", height: "20px", width: "20px" }}
              >
                +
              </button>
            </Box>
          </Box>
        </ListItem>
      </Box>
    </Box>
  );
};

export default ProductItem;
