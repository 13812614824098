import axios from "axios";

export const URL = "https://sierravitaback.com"; //https://sierra-vita-back-production.up.railway.app"//

export const getWines = () => {
  return async function (dispatch) {
    const resp = await fetch(`${URL}/wines/getWines`);
    const data = await resp.json();
    return dispatch({ type: "GET_WINES", payload: data });
  };
};

export const addItemsStorage = (object) => {
  return {
    type: "ADD_ITEMS_STORAGE",
    payload: object,
  };
};

export const addQuantityItems = (id) => {
  return {
    type: "ADD_QUANTITY_ITEMS",
    payload: id,
  };
};

export const subtractQuantityItems = (id) => {
  return {
    type: "SUBTRACT_QUANTITY_ITEMS",
    payload: id,
  };
};

export const getTotalItems = () => ({
  type: "GET_TOTAL_ITEMS",
});

export const getTotalPrice = () => ({
  type: "GET_TOTAL_PRICE",
});

export const deleteItemToCart = (id) => {
  return {
    type: "DELETE_ITEM_SHOPPING_CART",
    payload: id,
  };
};

export const switchLanguage = () => ({
  type: "SWITCH_LANGUAGE",
});

export const openShoppingCart = () => ({
  type: "OPEN_CART_STATE",
});

export function postWine(wine) {
  let formData = new FormData();
  formData.append("name", wine.name);
  formData.append("type", wine.type);
  formData.append("stock", wine.stock);
  formData.append("price", wine.price);
  formData.append("alcoholVol", wine.alcoholVol);
  formData.append("descriptionEN", wine.descriptionEN);
  formData.append("descriptionES", wine.descriptionES);
  formData.append("cont", wine.cont);
  formData.append("temp", wine.temp);
  formData.append("noseEN", wine.noseEN);
  formData.append("noseES", wine.noseES);
  formData.append("colorEN", wine.colorEN);
  formData.append("colorES", wine.colorES);
  formData.append("mouthEN", wine.mouthEN);
  formData.append("mouthES", wine.mouthES);
  formData.append("pairingEN", wine.pairingEN);
  formData.append("pairingES", wine.pairingES);
  formData.append("image", wine.images);
  return async function () {
    let post = await axios.post(`${URL}/admin/postwine`, formData, {
      headers: {
        "Content-Type": "Multipart/Form-Data",
      },
    });
    return post;
  };
}

export const getAllWinesAdmin = () => {
  return async function (dispatch) {
    try {
      const resp = await fetch(`${URL}/admin/getwines`);
      const data = await resp.json();
      return dispatch({ type: "GET_ALL_WINES_ADMIN", payload: data });
    } catch (error) {
      return error;
    }
  };
};

export function postNewsLetterEN(data) {
  return async function () {
    let post = await axios.post(`${URL}/admin/newsEN`, data);
    return post;
  };
}

export function postNewsLetterES(data) {
  return async function () {
    let post = await axios.post(`${URL}/admin/newsES`, data);
    return post;
  };
}

export async function subscribeToNewsletter(body) {
  try {
    const resp = await axios.post(`${URL}/mails/postsubscriber`, body);
    return resp;
  } catch (e) {
    return e.response;
  }
}


export const getAllSubs = () => {
  return async function (dispatch) {
    try {
      const resp = await fetch(`${URL}/admin/subscribers`);
      const data = await resp.json();
      return dispatch({ type: "GET_ALL_SUBS", payload: data });
    } catch (error) {
      return error;
    }
  };
};

export async function contactSierraVita(body) {
  try {
    const resp = await axios.post(`${URL}/mails/contactus`, body);
    return resp;
  } catch (e) {
    return e.response;
  }
}

export function unsubscribeToNewsLetter(data) {
  return async function () {
    let resp = await axios.put(`${URL}/admin/userErase`, data);
    return resp;
  };
}

export const getWineDetailInfoAdmin = (id) => {
  return async function (dispatch) {
    const resp = await fetch(`${URL}/wines/detail/${id}`);
    const data = await resp.json();
    return dispatch({
      type: "GET_WINE_DETAIL_INFO_ADMIN",
      payload: data,
    });
  };
};

export function updateWine(id, wine) {
  let mods = new FormData();
  mods.append("name", wine.name);
  mods.append("type", wine.type);
  mods.append("stock", wine.stock);
  mods.append("price", wine.price);
  mods.append("alcoholVol", wine.alcoholVol);
  mods.append("descriptionEN", wine.descriptionEN);
  mods.append("descriptionES", wine.descriptionES);
  mods.append("cont", wine.cont);
  mods.append("temp", wine.temp);
  mods.append("noseEN", wine.noseEN);
  mods.append("noseES", wine.noseES);
  mods.append("colorEN", wine.colorEN);
  mods.append("colorES", wine.colorES);
  mods.append("mouthEN", wine.mouthEN);
  mods.append("mouthES", wine.mouthES);
  mods.append("pairingEN", wine.pairingEN);
  mods.append("pairingES", wine.pairingES);
  mods.append("image", wine.images);
  mods.append("isActive", wine.isActive);
  mods.append("images", wine.images);
  mods.append("imageID", wine.imagesID);

  return async function () {
    let post = await axios.put(`${URL}/admin/updateWines/${id}`, mods, {
      headers: {
        "Content-Type": "Multipart/Form-Data",
      },
    });
    return post;
  };
}

export const getWineDetail = (id) => {
  return async function (dispatch) {
    const resp = await fetch(`${URL}/wines/detail/${id}`);
    const data = await resp.json();
    return dispatch({
      type: "GET_WINE_DETAIL",
      payload: data,
    });
  };
};

export const shipmentInformation = (object) => {
  return {
    type: "SHIPMENT_INFORMATION",
    payload: object,
  };
};

export const emptyWineAddedToCart = () => ({
  type: "EMPTY_WINE_ADDED_TO_CART",
});

export const productAlreadyInYourCart = (wine) => ({
  type: "PRODUCT_ALREADY_IN_YOUR_CART",
  payload: wine,
});

export const noStockAvailable = (wine) => ({
  type: "NO_STOCK_AVAILABLE",
  payload: wine,
});

export const getjwt = (loginPayload) => {
  return async function (dispatch) {
    const resp = await axios.post(`${URL}/login/login`, loginPayload);
    sessionStorage.setItem("token", JSON.stringify(resp.data.token));
    return dispatch({
      type: "SET_JWT",
      payload: JSON.stringify(resp.data.token),
    });
  };
};

export const postAdmin = (admin) => {
  return async function (dispatch) {
    const resp = await axios.post(
      `${URL}/admin/superuser`,
      admin
    );
    return resp;
  };
};

export const navScroll = (string) => {
  return {
    type: "NAV_SCROLL",
    payload: string,
  };
};

export const getAllAdmins = () => {
  return async function (dispatch) {
    try {
      const resp = await fetch(`${URL}/admin/getadmins`);
      const data = await resp.json();
      return dispatch({ type: "GET_ALL_ADMINS", payload: data });
    } catch (error) {
      return error;
    }
  };
};

export const getAdminDetail = (id) => {
  return async function (dispatch) {
    const resp = await fetch(`${URL}/admin/admindetail/${id}`);
    const data = await resp.json();
    return dispatch({
      type: "GET_ADMIN_DETAIL",
      payload: data,
    });
  };
};

export function updateAdmin(id, admin) {
  let mods = new FormData();
  mods.append("name", admin.name);
  mods.append("lastname", admin.lastname);
  mods.append("username", admin.username);
  mods.append("email", admin.email);
  mods.append("isActive", admin.isActive);

  return async function () {
    let post = await axios.put(`${URL}/admin/updateadmin/${id}`, mods, {
      headers: {
        "Content-Type": "Multipart/Form-Data",
      },
    });
    return post;
  };
}

export const getjwtAdmin = (loginPayload) => {
  return async function (dispatch) {
    const resp = await axios.post(`${URL}/admin/login`, loginPayload);
    sessionStorage.setItem("tokenadmin", JSON.stringify(resp.data.token));
    return dispatch({
      type: "SET_JWT",
      payload: JSON.stringify(resp.data.token),
    });
  };
};

export const getOrdersAdmin = () => {
  return async function (dispatch) {
    try {
      const resp = await fetch(`${URL}/admin/getorders`);
      const data = await resp.json();
      return dispatch({ type: "GET_ORDERS", payload: data });
    } catch (error) {
      return error;
    }
  };
};

export function updateOrder(id, mods) {
  fetch(`${URL}/admin/updateOrder/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ mods }),
  });
}
