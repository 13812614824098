import { Box, Button, Link,Typography } from '@mui/material'
import React from 'react'

function Stripelink() {
  return (
    <Box
    direction="column"
    component="div"
    alignContent="center"
    textAlign="center"
    alignItems="center"
    margin={7}
    sx={{
      '& .MuiTextField-root': { 
        m: 1, width: '40ch' 
      },
    }}
    >
    <a
      href="https://dashboard.stripe.com/login"
      target="_blank"
      rel="noreferrer">
      <Button
        type="submit" 
        sx={{ backgroundColor: "var(--color-gray)", marginRight: "1rem", padding: "1rem", border: "1px solid var(--color-text-dark)" }}
      > 
      <Typography
        sx={{
          fontSize: { xs: "1rem", sm: "1.5rem" },
          fontFamily: "var(--font-text)",
          color: "var(--color-text-dark)",
          fontWeight: "900",
        }}>
        Go to Stripe →
      </Typography>
      </Button>
    </a>
    </Box>
  )
}

export default Stripelink