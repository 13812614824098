import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from 'react-redux'
import { getjwt } from "../Redux/actions";
import style from "./Login.module.css";

const Login = () => {
	const dispatch = useDispatch()
	
	const [input, setInput] = useState({
		userName: "",
    password: ""
  });
	
  const [errors, setErrors] = useState({
		userName: "",
    password: "",
  });

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(getjwt(input));
		setInput({
			userName: "",
			password: "",
		})
    setTimeout(() => {
      window.location.reload();
    }, 1000);
		// window.location.reload();
	}
	
  // const [token, setToken] = useState("");
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (token && token?.length > 0) navigate("/");
  // }, [token]);

  const validateLogin = (input) => {
    let errors = { userName: "", password: "" };

    if (input.userName.length === 0) errors.userName = "Username is required";
    // else if (!/\S+@\S+\.\S+/.test(input.email))
      // errors.email = "Enter a valid email address";
    if (input.password.length === 0)
      errors.password = "Password is required";
    // else if (!/(?=.*[A-Z])/.test(input.password))
    //   errors.password = "At least one uppercase letter required";
    // else if (!/(?=.*[0-9].*[0-9])/.test(input.password))
    //   errors.password = "At least two numbers required";
    // else if (!/(?=.{8})/.test(input.password))
    //   errors.password = "At least 8 characters required";

    return errors;
  };

  const handleChangeLogin = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setErrors(validateLogin({ ...input, [e.target.name]: e.target.value }));
  };

  return (
    <Box
    direction="column"
    component="form"
    onSubmit={(e) => e.preventDefault()}
    className="login-container"
    textAlign="center"
    marginTop={8}
    sx={{backgroundColor:"var(--color-background-admin)"}}
    > 
    <Stack direction="column" alignItems="center" spacing={2}></Stack>
    <Typography
      variant="h3"
      component="h3"
      margin={3}
      fontFamily="var(--font-title)"
      sx={{
        fontSize: { xs: "2rem", sm: "3rem" },
        fontFamily: "var(--font-text)",
        color: "var(--color-text-dark)",
        fontWeight: "900"
        }}> Login
    </Typography>
			
    <Typography
      variant="h6"
      component="h6"
      fontFamily="var(--font-text)"
      sx={{
      fontSize: { xs: "1rem", sm: "2rem" },
      fontWeight: "600"}}
    > Username
    </Typography>
    <input
      onChange={handleChangeLogin}
      name="userName"
      value={input.userName}
      autoComplete="off"
      type="userName"
      id="log-userName"
      className={style.input_field}
      placeholder="Enter your username"
    />
    <Typography
      variant="p"
      component="p"
      fontFamily="var(--font-text)"
      className={`error-text ${errors?.userName && "show-error"}`}>
      {errors?.userName || "."}
    </Typography>

    <Typography
      variant="h6"
      component="h6"
      fontFamily="var(--font-text)"
      marginTop={1}
      sx={{
        fontSize: { xs: "1rem", sm: "2rem" },
        fontWeight: "600"}}
    > Password
    </Typography>
    <input
      onChange={handleChangeLogin}
      name="password" 
      value={input.password}
      autoComplete="current-password"
      type="password"
      id="log-password"
      className={style.input_field}
      placeholder="Enter your password"
    />
    <Typography
      variant="p"
      component="p"
      fontFamily="var(--font-text)"
      className={`error-text ${errors?.password && "show-error"}`}>
      {errors?.password || "."}
    </Typography>
{/* 
    <Typography
      variant="p"
      component="p"
      margin={1}
      fontFamily="var(--font-text)"
    > Please refresh the page after submit
    </Typography> */}

		<Button type="submit" variant="outlined" 
      onClick={handleSubmit} className="action-btn login-btn"
      sx={{ 
        backgroundColor: "var(--color-gray)", 
        marginRight: "1rem", 
        padding: "1rem", 
        border: "1px solid var(--color-text-dark)",
        marginBottom: "5rem",}}
      >
        <Typography
          sx={{
            fontSize: { xs: "1rem", sm: "1.5rem" },
            fontFamily: "var(--font-text)",
            color: "var(--color-text-dark)",
            fontWeight: "900",
          }}>Submit
        </Typography>
    </Button>
  </Box>
	);
};

export default Login;
