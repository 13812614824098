import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import style from "./SubToNewsletter.module.css";

export default function SubToNewsletter({
  subscriber,
  setSubscriber,
  email,
  setInput,
}) {
  const [t] = useTranslation("global");

  const closeModal = () => {
    setSubscriber({});
    setInput({ name: "", lastname: "", email: "" });
  };

  if (subscriber.hasOwnProperty("message"))
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            alignItems: "center",
            padding: "2rem",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: `translate(-50%, -50%) ${
              Object.keys(subscriber).length > 0 ? "scale(1)" : "scale(0)"
            }`,
            backgroundColor: "var(--color-background)",
            color: "var(--color-text-light)",
            boxShadow: "0 1rem 1rem rgba(0,0,0,0.8)",
            zIndex: "100",
            transition: "all 0.3s",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "2rem", textAlign: "center" }}>
              {`${email} ${t("component.alreadySubscribed")}`}
            </Typography>
          </Box>
          <button onClick={closeModal} className={style.wine_btn}>
            {t("component.gotIt")}
          </button>
        </Box>
        <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: `${Object.keys(subscriber).length > 0 ? "block" : "none"}`,
            zIndex: "99",
          }}
        ></Box>
      </>
    );
  else if (subscriber.hasOwnProperty("error"))
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            alignItems: "center",
            padding: "2rem",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: `translate(-50%, -50%) ${
              Object.keys(subscriber).length > 0 ? "scale(1)" : "scale(0)"
            }`,
            backgroundColor: "var(--color-background)",
            color: "var(--color-text-light)",
            boxShadow: "0 1rem 1rem rgba(0,0,0,0.8)",
            zIndex: "100",
            transition: "all 0.3s",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "2rem", textAlign: "center" }}>
              {t("component.wrong")}
            </Typography>
          </Box>
          <button onClick={closeModal} className={style.wine_btn}>
            {t("component.gotIt")}
          </button>
        </Box>
        <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: `${Object.keys(subscriber).length > 0 ? "block" : "none"}`,
            zIndex: "99",
          }}
        ></Box>
      </>
    );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          alignItems: "center",
          padding: "2rem",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: `translate(-50%, -50%) ${
            Object.keys(subscriber).length > 0 ? "scale(1)" : "scale(0)"
          }`,
          backgroundColor: "var(--color-background)",
          color: "var(--color-text-light)",
          boxShadow: "0 1rem 1rem rgba(0,0,0,0.8)",
          zIndex: "100",
          transition: "all 0.3s",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "2rem", textAlign: "center" }}>
            {`${t("component.welcome")} ${subscriber.name}! ${t(
              "component.thanksForSubscribing"
            )}`}
          </Typography>
        </Box>
        <button onClick={closeModal} className={style.wine_btn}>
          {t("component.gotIt")}
        </button>
      </Box>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: `${Object.keys(subscriber).length > 0 ? "block" : "none"}`,
          zIndex: "99",
        }}
      ></Box>
    </>
  );
}
