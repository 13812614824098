import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import style from "./Tasting.module.css";

export default function GalleryCarousel({ gallery }) {
  const [currentImage, setCurrentImage] = useState(0);

  const displayNextImage = () => {
    if (currentImage === gallery.length - 1) setCurrentImage(0);
    else setCurrentImage((prev) => ++prev);
  };

  const displayPreviousImage = () => {
    if (currentImage === 0) setCurrentImage(gallery.length - 1);
    else setCurrentImage((prev) => --prev);
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: { md: "none" },
        padding: { xs: "2rem", sm: "4rem" },
      }}
    >
      <button
        onClick={displayNextImage}
        className={`${style.carousel_btn} ${style.right_btn}`}
      >
        <ChevronRightOutlinedIcon sx={{ fontSize: "3.4rem" }} />
      </button>
      <Box
        className={style.img_container}
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "flex-start",
          // gap: "2rem",
          overflow: "hidden",
        }}
      >
        {gallery.map((image) => (
          <img
            key={image}
            src={image}
            alt="Process of collecting the grape"
            className={style.carousel_img}
            style={{ transform: `translateX(${currentImage * -100}%)` }}
          />
        ))}
      </Box>
      <button
        onClick={displayPreviousImage}
        className={`${style.carousel_btn} ${style.left_btn}`}
      >
        <ChevronLeftOutlinedIcon sx={{ fontSize: "3.4rem" }} />
      </button>
    </Box>
  );
}
