import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import WineBarIcon from '@mui/icons-material/WineBar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import { FacebookRounded, Instagram } from "@mui/icons-material";
import { useTranslation } from "react-i18next"
import SwitchLanguage from '../SwitchLanguage/SwitchLanguage';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
//import { Link, useLocation, useNavigate } from 'react-router-dom';
//import { useDispatch, useSelector } from 'react-redux';
//import { navScroll } from '../Redux/actions';
import style from './DrawerNav.module.css'
const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function DrawerLeft({ open, handleDrawerClose, navigatePage }) {

  const theme = useTheme();
  const [t] = useTranslation("global")

  return (
    <Box sx={{ display: 'flex' }} >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
        }}
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {/*---contenido del drawer */}
        <List sx={{ margin: '10px 20px' }}>
          <ListItem disablePadding onClick={() => { handleDrawerClose(); navigatePage() }} className={style.listItem}>
            <ListItemIcon sx={{ minWidth: '40px' }}><OtherHousesIcon sx={{ fontSize: "2rem" }} /></ListItemIcon>
            <Typography sx={{ fontSize: '1.3rem', fontFamily: "var(--font-text)" }}>{t("component.aboutUs")}</Typography>
          </ListItem>
          <ListItem disablePadding className={style.listItem} onClick={handleDrawerClose}>
            <ListItemIcon sx={{ minWidth: '40px' }}><WineBarIcon sx={{ fontSize: "2rem" }} /></ListItemIcon>
            <Link to="/experiences"><Typography sx={{ fontSize: '1.3rem', fontFamily: "var(--font-text)" }}> {t("component.experiences")}</Typography></Link>
          </ListItem>
          <ListItem disablePadding onClick={() => { handleDrawerClose(); navigatePage() }} className={style.listItem}>
            <ListItemIcon sx={{ minWidth: '40px' }}><MailIcon sx={{ fontSize: "2rem" }} /></ListItemIcon>
            <Typography sx={{ fontSize: '1.3rem', fontFamily: "var(--font-text)" }}>{t("component.contact")}</Typography>
          </ListItem>
          <ListItem disablePadding className={style.listItem} onClick={handleDrawerClose}>
            <a href="https://goo.gl/maps/KiF9t4fuFdpNinaK8" target="_blank" rel="noopener noreferrer">
              <ListItemIcon sx={{ minWidth: '40px' }}><LocationOnIcon sx={{ fontSize: "2rem" }} /></ListItemIcon>
              <Typography sx={{ fontSize: '1.3rem', fontFamily: "var(--font-text)" }}>{t("component.location")}</Typography>
            </a>
          </ListItem>
          <Box sx={{ display: { sm: "none" } }}>
            <ListItem disablePadding className={style.listItem} onClick={handleDrawerClose}>
              <a href="https://www.facebook.com/sierravita/" target="_blank" rel="noopener noreferrer">
                <ListItemIcon sx={{ minWidth: '40px' }}><FacebookRounded sx={{ fontSize: "2rem" }} /></ListItemIcon>
                <Typography sx={{ fontSize: '1.3rem', fontFamily: "var(--font-text)" }}>Facebook</Typography>
              </a>
            </ListItem>
            <ListItem disablePadding className={style.listItem} onClick={handleDrawerClose}>
              <a href="https://www.instagram.com/sierravitawinery/?hl=es" target="_blank" rel="noopener noreferrer">
                <ListItemIcon sx={{ minWidth: '40px' }}><Instagram sx={{ fontSize: "2rem" }} /></ListItemIcon>
                <Typography sx={{ fontSize: '1.3rem', fontFamily: "var(--font-text)" }}>Instagram</Typography>
              </a>
            </ListItem>
          </Box>
        </List>
        <Divider />
        <Box sx={{ flexGrow: 0, display: { xs: 'flex', lg: 'none' }, justifyContent: { xs: 'center' } }}>
          <SwitchLanguage />
        </Box>
      </Drawer>
    </Box>
  );
}