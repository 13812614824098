import React from "react";
import { Box, Divider, List, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProductItem from "../ProductItem/ProductItem";
import { getTotalItems, getTotalPrice } from "../../Redux/actions";
import style from "./ProductCart.module.css";

const ProductCart = () => {
  const [t] = useTranslation("global");
  const TotalItems = useSelector((state) => state.totalItemsCart);
  const TotalPrice = useSelector((state) => state.totalPriceProducts);
  const ItemsShoppingCart = useSelector((state) => state.itemsShoppingCart);
  let dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTotalItems());
    dispatch(getTotalPrice());
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
        <Typography
          sx={{
            margin: { xs: "5px", sm: "10px" },
            color: "#fefdfd",
            fontSize: "1.5rem",
          }}
        >
          {t("component.items")}: {TotalItems}
        </Typography>
        <Typography
          sx={{
            margin: { xs: "5px", sm: "10px" },
            color: "#fefdfd",
            fontSize: "1.5rem",
          }}
        >
          {t("component.totalPrice")}: ${TotalPrice}
        </Typography>
      </Box>
      <Divider sx={{ backgroundColor: "#fefdfd" }} />
      <List
        sx={{
          margin: "20px 0px",
          backgroundColor: "#231f20",
          padding: { xs: "5px", sm: "10px" },
        }}
        className={style.items_box}
      >
        {ItemsShoppingCart.map((item) => {
          return (
            <ProductItem
              key={item._id}
              id={item._id}
              img={item.images}
              price={item.price}
              quantity={item.quantity}
              name={item.name}
            />
          );
        })}
      </List>
    </>
  );
};

export default ProductCart;
