import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
//import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack, Switch, TextField, Typography } from '@mui/material';
import { updateWine } from '../../Redux/actions';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 1),
  backgroundColor: '#121212',
  width: '400px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

function EditForm({ openDrawer, closeDrawer }) {

  /*  const [t] = useTranslation("global") */
  const theme = useTheme();
  const InfoWine = useSelector(state => state.wineDetailInfoAdmin)
  const dispatch = useDispatch();

  const [input, setInput] = React.useState({})
  const [active, setActive] = React.useState(InfoWine.isActive)

  React.useEffect(() => {
    if (InfoWine) {
      setActive(InfoWine.isActive)
      setInput(
        {
          name: InfoWine.name,
          type: InfoWine.type,
          stock: InfoWine.stock,
          price: InfoWine.price,
          alcoholVol: InfoWine.alcoholVol,
          descriptionEN: InfoWine.descriptionEN,
          descriptionES: InfoWine.descriptionES,
          isActive: active || InfoWine.isActive, //* Si no se hace un cambio en el campo "active" este queda undefine y rompe ld BD"
          cont: InfoWine.cont,
          temp: InfoWine.temp,
          noseEN: InfoWine.noseEN,
          noseES: InfoWine.noseES,
          colorEN: InfoWine.colorEN,
          colorES: InfoWine.colorES,
          mouthEN: InfoWine.mouthEN,
          mouthES: InfoWine.mouthES,
          pairingEN: InfoWine.pairingEN,
          pairingES: InfoWine.pairingES,
          images: InfoWine.images,
          imagesID: InfoWine.imagesID,
        }
      )
    }
    return () => {
      setInput({
        name: "",
        type: "",
        stock: 0,
        price: 0,
        alcoholVol: 0,
        descriptionEN: "",
        descriptionES: "",
        isActive: "",
        cont: 0,
        temp: 0,
        noseEN: "",
        noseES: "",
        colorEN: "",
        colorES: "",
        mouthEN: "",
        mouthES: "",
        pairingEN: "",
        pairingES: "",
        image: [],
      })

    }
  }, [InfoWine, closeDrawer])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    })
  }

  const handleSwitch = () => {
    setActive(!active)
    setInput({
      ...input,
      isActive: !active
    })
  }

  function handleChangeImages(e) {
    const { name } = e.target;
    setInput({
      ...input,
      [name]: e.target.files[0],
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(updateWine(InfoWine._id, input))
    closeDrawer(false)
    //alert("Wine created") 
  }

  return (
    <Box sx={{ display: 'flex' }} >
      <Drawer
        sx={{
          flexShrink: 0
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => closeDrawer(false)}
      >
        <DrawerHeader>
          <IconButton onClick={() => closeDrawer(false)} >
            {theme.direction === 'ltr' ? <ChevronRightIcon sx={{ color: '#fefdfd' }} /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ backgroundColor: '#fefdfd' }} />
        <Box
          direction="column"
          component="form"
          onSubmit={e => handleSubmit(e)}
          sx={{
            '& .MuiTextField-root': { m: 1, width: '50ch' },
            marginBottom: "5rem"
          }}
        >
          <Stack direction="column" alignItems="center" spacing={2}>
            <Typography variant="h4" component="h4" margin={2} sx={{
              fontSize: { xs: "2rem", sm: "3rem" },
              fontFamily: "var(--font-text)",
              color: "var(--color-text-dark)",
              fontWeight: "900"
            }}>
              Edit Wine
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={!active ? { color: 'red', fontSize: '15px', fontWeight: 'bold' } : { color: 'gray', fontSize: '15px' }}>Disabled</Typography>
              <Switch
                checked={active}
                onChange={() => handleSwitch()}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography
                sx={active ? { color: 'green', fontSize: '15px', fontWeight: 'bold' } : { color: 'gray', fontSize: '15px' }}
              >Active</Typography>
            </Box>
            <TextField
              name="name"
              label="Name"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.name}
            />
            <TextField
              name="type"
              label="Type"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.type}
            />
            <TextField
              name="descriptionEN"
              label="English Description"
              multiline
              maxRows={5}
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.descriptionEN}
            />
            <TextField
              name="descriptionES"
              label="Spanish Description"
              multiline
              maxRows={5}
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.descriptionES}
            />
            <TextField
              name="alcoholVol"
              label="Alcohol Volume"
              type="number"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.alcoholVol}
            />
            <TextField
              name="stock"
              label="Stock"
              type="number"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.stock}
            />
            <TextField
              name="cont"
              label="Contenido"
              type="number"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.cont}
            />
            <TextField
              name="temp"
              label="Temperature"
              type="number"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.temp}
            />
            <TextField
              name="noseES"
              label="Nose EN"
              multiline
              maxRows={5}
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.noseES}
            />
            <TextField
              name="noseEN"
              label="Nose EN"
              multiline
              maxRows={5}
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.noseEN}
            />
            <TextField
              name="colorES"
              label="Color ES"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.colorES}
            />
            <TextField
              name="colorEN"
              label="Color EN"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.colorEN}
            />
            <TextField
              name="mouthEN"
              label="Mouth EN"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.mouthEN}
            />
            <TextField
              name="mouthES"
              label="Mouth ES"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.mouthES}
            />
            <TextField
              name="pairingEN"
              label="PairingEN"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.pairingEN}
            />
            <TextField
              name="pairingES"
              label="PairingES"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.pairingES}
            />
            <TextField
              name="price"
              label="Price"
              type="number"
              InputProps={{
                style: {
                  fontSize: 20,
                  color: "var(--color-text-dark)",
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                },
              }}
              onChange={e => handleChange(e)}
              value={input.price}
            />
            <Button
              variant="contained"
              component="label"
              sx={{
                backgroundColor: "var(--color-gray)",
                color: "var(--color-text-dark)",
                marginRight: "1rem",
                padding: "1rem",
                border: "1px solid var(--color-text-dark)",
                fontSize: 15,
              }}
            > Upload Image
              <input
                hidden
                name="images"
                accept="image/*"
                multiple type="file"
                onInput={handleChangeImages}
              />
            </Button>
            <Button
              type="submit"
              variant="outlined"
              sx={{ backgroundColor: "var(--color-gray)", marginRight: "1rem", padding: "1rem", border: "1px solid var(--color-text-dark)" }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "1rem", sm: "1.5rem" },
                  fontFamily: "var(--font-text)",
                  color: "var(--color-text-dark)",
                  fontWeight: "900",
                }}> Save Wine
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </Box>
  );
}

export default EditForm;