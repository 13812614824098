import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import vineyard_small from "../../../images/vineyard_small.jpg";
import vineyard from "../../../images/vineyard.jpg";
import style from "./Vineyard.module.css";

export default function Vineyard() {
  const [t] = useTranslation("global");

  return (
    <Box
      id="section--vineyard"
      sx={{
        backgroundColor: "var(--color-background)",
      }}
    >
      <Grid
        className="section_hidden section"
        container
        spacing={{ sm: 2, md: 4 }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={6} sx={{ padding: { xs: "2rem", sm: 0 } }}>
          <img
            className={`${style.worker_img} lazy_img`}
            src={vineyard_small}
            data-src={vineyard}
            alt="Vineyard worker"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h2"
            component="h2"
            gutterBottom
            sx={{
              padding: "0 3.5rem",
              color: "var(--color-blue)",
              fontFamily: "var(--font-title)",
            }}
          >
            {t("component.vineyard")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              padding: "0 3.5rem 5rem",
              lineHeight: 1.7,
              color: "var(--color-text-light)",
              fontFamily: "var(--font-text)",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "1.8rem",
                  md: "2rem",
                },
                fontFamily: "var(--font-text)"
              }}
            >
              {t("component.vineyardText")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
