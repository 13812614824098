const patternText = new RegExp(/^[a-zA-Z ]+$/);
const patternEmail = new RegExp(
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const patternNumber = new RegExp(/^[0-9]*(\.?)[0-9]+$/);
const patternNumberAndText = new RegExp(/^[0-9a-zA-Z ]+$/);

export function validate(input, setMesaggeError) {
  let errors = {};
  if (!input.name || input.name?.length <= 0 || !patternText.test(input.name)) {
    errors.name = "Please fill in your name";
  }
  if (
    !input.email ||
    input.email?.length <= 0 ||
    !patternEmail.test(input.email)
  ) {
    errors.email = "Please complete the email";
  }
  if (!input.phone || input.phone < 1 || !patternNumber.test(input.phone)) {
    errors.phone = "Please fill in the number";
  }
  if (
    !input.shippingAddress ||
    input.shippingAddress?.length <= 0 ||
    !patternNumberAndText.test(input.shippingAddress)
  ) {
    errors.shippingAddress = "Please fill in the address";
  }
  if (!input.city || input.city?.length <= 0 || !patternText.test(input.city)) {
    errors.city = "Please fill in the city";
  }
  if (
    !input.state ||
    input.state?.length <= 0 ||
    !patternText.test(input.state)
  ) {
    errors.state = "Please fill in the state";
  }
  /* if (!input.keyRFC || input.keyRFC?.length <= 0 || !patternNumberAndText.test(input.keyRFC)) {
    errors.keyRFC = "Please fill in the key";
  } */
  if (
    !input.postalCode ||
    input.postalCode < 0 ||
    !patternNumber.test(input.postalCode)
  ) {
    errors.postalCode = "Please fill in the postal code";
  }
  Object.keys(errors).length ? setMesaggeError(true) : setMesaggeError(false);
  return errors;
}
