import React from "react";
import { Grid } from '@mui/material';
import CreateWine from "./CreateWine";
import EditWines from "./EditWines";

function Wines() {
  return (
    <Grid
      container
      spacing={{ sm: 2, md: 4 }}
      alignItems="center"
      justifyContent="center"
      sx={{backgroundColor:"var(--color-background-admin)"}}
    >
      <Grid item xs={12} sm={12} md={12} >
        <CreateWine />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <EditWines />
      </Grid> 
    </Grid>
  )
}
export default Wines;