import React from 'react'
import { Box, Checkbox, Divider, List, Typography } from '@mui/material'
import OrderProducts from '../OrderProduct/OrderProducts';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalItems, getTotalPrice, shipmentInformation } from '../../Redux/actions';
import { useTranslation } from "react-i18next";
import { validate } from '../../../utils/checkFormValidate';
import style from '../Checkout.module.css';
import { useLocation, useNavigate } from 'react-router-dom';

const ShippingForm = ({ setView }) => {

  const [t] = useTranslation("global");
  const [errors, setErrors] = React.useState({});
  const [formFields, setFormFields] = React.useState({
    name: '',
    email: '',
    phone: '',
    shippingAddress: '',
    city: '',
    state: '',
    postalCode: '',
    shippingRemarks: '',
    keyRFC: '',
  });
  const [winesToCart, setWinesToCart] = React.useState([])
  const [mesaggeError, setMesaggeError] = React.useState(true)
  const WinesCart = useSelector(state => state.itemsShoppingCart)
  const TotalItems = useSelector(state => state.totalItemsCart)
  const TotalPrice = useSelector(state => state.totalPriceProducts)
  const location = useNavigate()
  const dispatch = useDispatch()
  const standardDelivery = 350.00;

  React.useEffect(() => {
    if (winesToCart.length < 1) {
      setWinesToCart(WinesCart)
    }
    dispatch(getTotalItems())
    dispatch(getTotalPrice())
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormFields({ ...formFields, [name]: value })
    setErrors(validate({
      ...formFields, [name]: value
    }, setMesaggeError))
  }

  const sendForm = () => {
    dispatch(shipmentInformation(formFields))
    setView(false)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} className={style.box_container}>
      <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
        <button onClick={() => location('/')}>
          <ChevronLeftIcon />
          {t("component.back")}
        </button>
        {/*----------INFORMACION DE ENVIO--------------------- */}
        <Box className={style.box} >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: '#61a6ab', marginLeft: '10px', fontSize: '1.5rem' }}>{t("component.shipmentInformation")}</Typography>
            <span>{t("component.required")}</span>
          </Box>
          <Divider sx={{ background: '#fefdfd' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', }} className={style.box_labels} >
            <label>{t("component.fullName")}</label>
            <input type="text" name="name" onChange={handleChange}
              error={errors.name} />
            {mesaggeError && <span>{errors.name}</span>}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }} className={style.box_labels}>
            <label>Email</label>
            <input type="email" name="email" onChange={handleChange} />
            {mesaggeError && <span>{errors.email}</span>}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', }} className={style.box_labels} >
            <label>{t("component.phone")}</label>
            <input type="text" name="phone" onChange={handleChange} />
            {mesaggeError && <span>{errors.phone}</span>}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', }} className={style.box_labels} >
            <label>{t("component.shippingAddress")}</label>
            <input type="text" name="shippingAddress" onChange={handleChange} />
            {mesaggeError && <span>{errors.shippingAddress}</span>}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, marginTop: { sm: '15px' }, justifyContent: 'space-between' }} >
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: { xs: '15px', sm: '0px' } }}>
              <label>{t("component.city")}</label>
              <input type="text" name="city" onChange={handleChange} />
              {mesaggeError && <span>{errors.city}</span>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: { xs: '15px', sm: '0px' } }}>
              <label>{t("component.state")}</label>
              <input type="text" name="state" onChange={handleChange} />
              {mesaggeError && <span>{errors.state}</span>}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, marginTop: { sm: '15px' }, justifyContent: 'space-between' }} >
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: { xs: '15px', sm: '0px' } }}>
              <label>{t("component.postalCode")}</label>
              <input type="text" name="postalCode" onChange={handleChange} />
              {mesaggeError && <span>{errors.postalCode}</span>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: { xs: '15px', sm: '0px' } }}>
              <label>{t("component.keyRFC")}</label>
              <input type="text" name="keyRFC" onChange={handleChange} />
              {mesaggeError && <span>{errors.keyRFC}</span>}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', }} className={style.box_labels} >
            <label>{t("component.shippingRemarks")}</label>
            <textarea type="text" name="shippingRemarks" rows={3} cols={105} onChange={handleChange} />
            {mesaggeError && <span>{errors.shippingRemarks}</span>}
          </Box>
        </Box>
        {/*------------METODO DE ENVIO--------------------- */}
        <Box className={style.box} >
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ color: '#61a6ab', marginLeft: '10px', fontSize: '1.5rem' }}>{t("component.shippingMethod")}</Typography>
          </Box>
          <Divider sx={{ background: '#fefdfd' }} />
          <Checkbox
            sx={{ color: '#61a6ab', '&.Mui-checked': { color: '#61a6ab' } }}
            checked={true}
          />
          <span>{t("component.nationalTerritoryStandardShipping")} ${standardDelivery}</span>
        </Box>
      </Box>
      {/*------------LADO DERECHO------------------- */}
      <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
        {/*----LISTA DE VINOS----------- */}
        <Box className={style.box} sx={{ marginTop: '40px' }} >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: '#61a6ab', marginLeft: '10px', fontSize: '1.5rem' }}>{t("component.items")}</Typography>
            <span>Requerido</span>
          </Box>
          <Divider sx={{ background: '#fefdfd' }} />
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column' } }}>
            <Typography sx={{ margin: { xs: '5px', sm: '10px' }, color: '#fefdfd', fontSize: '1.5rem' }}>
              {t("component.items")}: {TotalItems}
            </Typography>
            <Typography sx={{ margin: { xs: '5px', sm: '10px' }, color: '#fefdfd', fontSize: '1.5rem' }}>
              {t("component.nationalTerritoryStandardShipping")}: ${standardDelivery}
            </Typography>
            <Typography sx={{ margin: { xs: '5px', sm: '10px' }, color: '#fefdfd', fontSize: '1.5rem' }}>
              {t("component.totalPrice")}: ${TotalPrice}
            </Typography>
          </Box>
          <List sx={{ margin: '20px 0px', padding: { xs: '5px', sm: '10px' } }} className={style.items_box} >
            {winesToCart.map(item => {
              return (
                <OrderProducts
                  key={item.id}
                  id={item.id}
                  img={item.images}
                  price={item.price}
                  quantity={item.quantity}
                  name={item.name}
                />
              )
            })}
          </List>
        </Box>
        {/*-------FINALIZAR COMPRA----------------------*/}
        <Box sx={{ margin: '15px', textAlign: 'center' }}>
          <button className={style.button_final} onClick={() => sendForm()} disabled={mesaggeError}>
            {t("component.next")}
          </button>
        </Box>
      </Box>
    </Box >
  )
}

export default ShippingForm