import { useTranslateImage } from "../../hooks/useTranslateImage";
import { Box, Typography } from "@mui/material";
import image1 from "../../images/experiences/airbnb/image1.webp";
import image2 from "../../images/experiences/airbnb/image2.webp";
import image3 from "../../images/experiences/airbnb/image3.webp";
import image4 from "../../images/experiences/airbnb/image4.webp";
import image5 from "../../images/experiences/airbnb/image5.webp";
import image6 from "../../images/experiences/airbnb/image6.webp";
import image7 from "../../images/experiences/airbnb/image7.webp";
import image8 from "../../images/experiences/airbnb/image8.webp";
import style from "./Experiences.module.css";

const images = [image1, image2, image3, image4, image5, image6, image7, image8];

export default function Airbnb({ t }) {
  const currentImage = useTranslateImage(images);

  return (
    <Box
      className={"section_hidden section"}
      component="section"
      sx={{
        display: "grid",
        gridTemplateColumns: { lg: "1fr 2fr" },
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
        gap: "2rem",
        marginBottom: "6rem",
        padding: { sm: "0 2rem", md: "0 3rem", lg: "0 2rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          overflow: "hidden",
          width: { xs: "90%", sm: "70%", md: "50%", lg: "100%" },
          justifySelf: "center",
        }}
      >
        {images.map((image) => (
          <img
            key={image}
            className={style.airbnb_carousel_img}
            src={image}
            alt="People enjoying a tasting at Sierra Vita winery"
            style={{ transform: `translateX(${currentImage * -100}%)` }}
          />
        ))}
      </Box>
      <Box
        component="article"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          backgroundColor: "var(--color-background)",
          padding: { xs: "2rem", sm: "3rem 6rem", md: "3rem 10rem" },
          borderRadius: "1.4rem",
          boxShadow: {
            xs: "0 1rem var(--color-blue)",
            sm: "1rem 1rem var(--color-blue)",
          },
          // gridRow: { xs: "2", lg: "1" },
          width: { xs: "90%", sm: "100%" },
        }}
      >
        <Typography
          component="h2"
          sx={{
            gridColumn: "1/-1",
            justifySelf: "center",
            color: "var(--color-blue)",
            fontSize: "3rem",
            fontWeight: "600",
            textAlign: { xs: "center", sm: "left" },
            letterSpacing: "-0.5px",
            fontFamily: "var(--font-text)",
          }}
        >
          Once Pueblos Airbnb
        </Typography>
        <Typography
          sx={{
            fontSize: "1.6rem",
            lineHeight: "1.6",
            fontFamily: "var(--font-text)",
          }}
        >
          {t("component.airbnbText1")}
        </Typography>
        <Typography
          sx={{
            fontSize: "1.6rem",
            lineHeight: "1.6",
            fontFamily: "var(--font-text)",
          }}
        >
          {t("component.airbnbText2")}
        </Typography>
        <Box
          sx={{
            alignSelf: "center",
            display: "flex",
            gap: "2rem",
            marginBottom: "1rem",
          }}
        >
          <a
            className={`${style.reserve_btn} ${style.whatsapp_btn}`}
            href="https://www.airbnb.mx/rooms/47313189?source_impression_id=p3_1675789608_gkLkur1tbtMH8gkO"
            target="_blank"
            rel="noreferrer"
          >
            {t("component.findOutMore")} &rarr;
          </a>
        </Box>
      </Box>
    </Box>
  );
}
