import { useEffect, useState } from "react";

export const useTranslateImage = (images) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const imageCarouselAnimation = setInterval(() => {
      if (currentImage === images.length - 1) {
        setCurrentImage(0);
      } else setCurrentImage((prev) => ++prev);
    }, 3000);

    return () => {
      clearInterval(imageCarouselAnimation);
    };
  }, [currentImage]);

  return currentImage;
};
