import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import ImageDisplay from "./ImageDisplay";
import GalleryCarousel from "./GalleryCarousel";
import gallery1_small from "../../../images/gallery/gallery1_small.jpg";
import gallery1 from "../../../images/gallery/gallery1.jpg";
import gallery2_small from "../../../images/gallery/gallery2_small.jpg";
import gallery2 from "../../../images/gallery/gallery2.jpg";
import gallery3_small from "../../../images/gallery/gallery3_small.jpg";
import gallery3 from "../../../images/gallery/gallery3.jpg";
import gallery4_small from "../../../images/gallery/gallery4_small.jpg";
import gallery4 from "../../../images/gallery/gallery4.jpg";
import gallery5_small from "../../../images/gallery/gallery5_small.jpg";
import gallery5 from "../../../images/gallery/gallery5.jpg";
import gallery6_small from "../../../images/gallery/gallery6_small.jpg";
import gallery6 from "../../../images/gallery/gallery6.jpg";
import gallery7_small from "../../../images/gallery/gallery7_small.jpg";
import gallery7 from "../../../images/gallery/gallery7.jpg";
import gallery8_small from "../../../images/gallery/gallery8_small.jpg";
import gallery8 from "../../../images/gallery/gallery8.jpg";
import gallery9_small from "../../../images/gallery/gallery9_small.jpg";
import gallery9 from "../../../images/gallery/gallery9.jpg";
import gallery10_small from "../../../images/gallery/gallery10_small.jpg";
import gallery10 from "../../../images/gallery/gallery10.jpg";
import gallery11_small from "../../../images/gallery/gallery11_small.jpg";
import gallery11 from "../../../images/gallery/gallery11.jpg";
import gallery12_small from "../../../images/gallery/gallery12_small.jpg";
import gallery12 from "../../../images/gallery/gallery12.jpg";
import style from "./Tasting.module.css";

const gallery = [
  gallery1_small,
  gallery2_small,
  gallery3_small,
  gallery4_small,
  gallery5_small,
  gallery6_small,
  gallery7_small,
  gallery8_small,
  gallery9_small,
  gallery10_small,
  gallery11_small,
  gallery12_small,
];

const galleryGoodQuality = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  gallery12,
];

export default function TastingGallery() {
  const [imageOpened, setImageOpened] = useState(false);

  const [t] = useTranslation("global");

  const openImage = (image) => setImageOpened(image);

  window.addEventListener("keydown", (e) => {
    const currentImageIndex = galleryGoodQuality.indexOf(imageOpened) || null;
    if (
      imageOpened &&
      e.key === "ArrowRight" &&
      imageOpened !== galleryGoodQuality.at(-1)
    )
      setImageOpened(galleryGoodQuality[currentImageIndex + 1]);
    else if (
      imageOpened &&
      e.key === "ArrowLeft" &&
      imageOpened !== galleryGoodQuality.at(0)
    )
      setImageOpened(galleryGoodQuality[currentImageIndex - 1]);
    else if (
      imageOpened &&
      e.key === "ArrowRight" &&
      imageOpened === galleryGoodQuality.at(-1)
    )
      setImageOpened(galleryGoodQuality.at(0));
    else if (
      imageOpened &&
      e.key === "ArrowLeft" &&
      imageOpened === galleryGoodQuality.at(0)
    )
      setImageOpened(galleryGoodQuality.at(-1));
  });

  return (
    <Box
      id="section-gallery"
      component="section"
      sx={{
        backgroundColor: "var(--color-background)",
        // padding: "2rem",
        paddingBottom: "9.6rem",
        position: "relative",
      }}
    >
      <Grid
        className="section_hidden section"
        container
        justifyContent="center"
        alignItems="center"
        flexDirection={{ md: "column-reverse", lg: "row" }}
      >
        <Grid
          container
          item
          xs={12}
          lg={6}
          sx={{
            display: { xs: "none", md: "grid" },
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "2rem",
            padding: { md: "0 10rem", lg: "2rem" },
          }}
        >
          {gallery.map((image, i) => (
            <Grid
              component="figure"
              key={image}
              item
              sx={{
                overflow: "hidden",
              }}
            >
              <img
                onClick={() => openImage(galleryGoodQuality[i])}
                id={`img--${i}`}
                className={`${style.gallery_img} lazy_img`}
                src={image}
                data-src={galleryGoodQuality[i]}
                alt="Process of collecting the grape"
              />
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          // alignSelf="center"
          sx={{
            fontSize: { xs: "1.8rem", sm: "2rem" },
            lineHeight: 1.7,
            color: "var(--color-text-light)",
            padding: { xs: "2rem", sm: "5rem", md: "10rem", lg: "0 8rem" },
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            marginBottom={2}
            sx={{
              textAlign: "center",
              fontFamily: "var(--font-title)",
              // textTransform: "uppercase",
              color: "var(--color-blue)",
              fontSize: "3.6rem",
            }}
          >
            {t("component.balance")}
          </Typography>
          <Box
            className={style.tasting_text_container}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "6rem",
              fontFamily: "var(--font-text)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <Typography sx={{ 
                fontSize: { xs: "1.8rem", sm: "2rem" }, 
                fontFamily: "var(--font-text)" 
              }}>
                {t("component.balanceTextOne")}
              </Typography>
              <Typography sx={{ 
                fontSize: { xs: "1.8rem", sm: "2rem" },
                fontFamily: "var(--font-text)"
              }}>
                {t("component.balanceTextTwo")}
              </Typography>
            </Box>
            <Link className={style.tasting_btn} to="/experiences">
              {t("component.tasting")} &rarr;
            </Link>
          </Box>
        </Grid>

        <GalleryCarousel gallery={galleryGoodQuality} />
      </Grid>
      {imageOpened && (
        <ImageDisplay
          imageOpened={imageOpened}
          setImageOpened={setImageOpened}
          image={imageOpened}
        />
      )}
    </Box>
  );
}
