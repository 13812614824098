import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  addItemsStorage,
  getWineDetail,
  getWines,
  noStockAvailable,
} from "../Redux/actions";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import style from "./Detail.module.css";
import AddedToCart from "../Modals/AddedToCart/AddedToCart";
import Footer from "../Home/Footer/Footer";
import Banner from "../Home/Banner/Banner";

function Detail() {
  const wineDetail = useSelector((state) => state.wineDetail);
  const wineAddedToCart = useSelector((state) => state.lastWineAddedToCart);
  const { id } = useParams();
  const wines = useSelector((state) => state.wines);
  const otherWines = wines.filter((wine) => wine._id !== id);
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  const {
    _id,
    name,
    type,
    stock,
    price,
    alcoholVol,
    cont,
    temp,
    noseEN,
    noseES,
    colorEN,
    colorES,
    mouthEN,
    mouthES,
    pairingEN,
    pairingES,
    images,
  } = useSelector((state) => state.wineDetail);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getWineDetail(id));
    dispatch(getWines());
  }, [dispatch, id, wineDetail.length]);

  /////////////////////////////
  const handleAddItemToCart = () => {
    if (stock > 0) {
      dispatch(addItemsStorage(wineDetail));
    } else dispatch(noStockAvailable(wineDetail));
  };
  ////////////////////////////

  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{textAlign:{ xs: "center", md: "left" }}}
        justifyContent="center"
        className={style.container}
      >
        {Object.keys(wineDetail).length === 0 || id !== _id ? (
          <div className={style["lds-dual-ring"]}></div>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={4}>
              <img
                src={`https://${images}`}
                alt={`${name} wine`}
                style={{ height: 350 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={12} md={6}>
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "3.5rem",
                      fontFamily: "var(--font-title)",
                    }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    component="h3"
                    sx={{
                      fontSize: "2rem",
                      color: "var(--color-blue)",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    {type}
                  </Typography>
                  <Typography
                    component="h3"
                    gutterBottom
                    sx={{
                      fontSize: "2rem",
                      color: "var(--color-blue)",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    ${price} | {cont} mL
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography
                    component="h3"
                    gutterBottom
                    sx={{
                      fontSize: "1.8rem",
                      fontFamily: "var(--font-text)",
                      textTransform: "uppercase",
                    }}
                  >
                    {`${stock}` === '0' ?  `${t("component.out")}` : `${stock} ${t("component.available")}`}
                  </Typography>
                  <button
                    className={style.wine_btn}
                    onClick={handleAddItemToCart}
                  >
                    {t("component.addToCart")}
                  </button>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography
                    component="h2"
                    sx={{
                      fontSize: "1.5rem",
                      color: "var(--color-blue)",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    Color:
                  </Typography>
                  <Typography
                    component="h2"
                    gutterBottom
                    sx={{
                      fontSize: "1.5rem",
                      marginBottom: "1em",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    {t("component.language") === "EN" ? colorEN : colorES}
                  </Typography>
                  <Typography
                    component="h2"
                    sx={{
                      fontSize: "1.5rem",
                      color: "var(--color-blue)",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    {t("component.nose")}:
                  </Typography>
                  <Typography
                    component="h2"
                    gutterBottom
                    sx={{
                      fontSize: "1.5rem",
                      marginBottom: "1em",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    {t("component.language") === "EN" ? noseEN : noseES}
                  </Typography>
                  <Typography
                    component="h2"
                    sx={{
                      fontSize: "1.5rem",
                      color: "var(--color-blue)",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    {t("component.mouth")}:
                  </Typography>
                  <Typography
                    component="h2"
                    gutterBottom
                    sx={{
                      fontSize: "1.5rem",
                      marginBottom: "1em",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    {t("component.language") === "EN" ? mouthEN : mouthES}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography
                    component="h2"
                    gutterBottom
                    sx={{
                      fontSize: "1.5rem",
                      marginBottom: "1em",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    {alcoholVol} Alc. Vol.
                  </Typography>
                  <Typography
                    component="h2"
                    sx={{
                      fontSize: "1.5rem",
                      color: "var(--color-blue)",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    {t("component.pairing")}:
                  </Typography>
                  <Typography
                    component="h2"
                    gutterBottom
                    sx={{
                      fontSize: "1.5rem",
                      marginBottom: "1em",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    {t("component.language") === "EN" ? pairingEN : pairingES}
                  </Typography>
                  <Typography
                    component="h2"
                    sx={{
                      fontSize: "1.5rem",
                      color: "var(--color-blue)",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    {t("component.temp")}:
                  </Typography>
                  <Typography
                    component="h2"
                    gutterBottom
                    sx={{
                      fontSize: "1.5rem",
                      marginBottom: "1em",
                      fontFamily: "var(--font-text)",
                    }}
                  >
                    {temp}°C
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Banner/>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(30rem, 1fr))",
          justifyContent: "center",
          justifyItems: "center",
          alignItems: "center",
          rowGap: "4rem",
          padding: "0 6rem",
          marginBottom: "4rem",
        }}
      >
        <Typography
          component="h2"
          sx={{
            gridColumn: "1/-1",
            justifySelf: "start",
            fontSize: "4rem",
            color: "var(--color-blue)",
            fontFamily: "var(--font-title)",
            marginBottom: "3rem",
          }}
        >
          {t("component.itMayInterestYou")}
        </Typography>
        {otherWines.map((wine) => (
          <Box
            component="figure"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "2rem",
              padding: "2rem 0",
              transition: "all 0.5s",
              "&:hover": {
                boxShadow: "0 5px 10px black",
                transform: "translateY(-1rem)",
              },
            }}
          > <Link to={`/detail/${wine._id}`} className={style.detail_name}>
              <Typography
                component="span"
                sx={{ color: "var(--color-text-light)", fontSize: "2rem", transition:"all 0.3s", "&:hover": {
                  color: "var(--color-blue)",
                },}}
              >
                {wine.name}
              </Typography>
            </Link>
            <Link to={`/detail/${wine._id}`}>
              <img
                src={`https://${wine.images}`}
                alt={wine.name}
                className={style.wine_img}
              />
            </Link>
            <Link to={`/detail/${wine._id}`} className={style.detail_btn}>
              {t("component.viewDetail")}
            </Link>
          </Box>
        ))}
      </Box>
      <Footer />
      {/* {Object.keys(wineAddedToCart).length > 0 && ( */}
      <AddedToCart wine={wineAddedToCart} />
      {/* )} */}
    </>
  );
}

export default Detail;
