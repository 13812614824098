import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import RestaurantOncePueblos from "./RestaurantOncePueblos";
import TastingOptions from "./TastingOptions";
import WineTerrace from "./WineTerrace";
import Airbnb from "./Airbnb";
import Footer from "../Home/Footer/Footer";

export default function Experiences() {
  const [t] = useTranslation("global");

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Box
      sx={{
        backgroundImage:
          "radial-gradient(circle,rgb(151, 167, 168), #444, #222)",
      }}
    >
      <Box
        sx={{
          maxWidth: "130rem",
          margin: "auto",
          color: "var(--color-text-light)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "6rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            marginTop: "2rem",
            padding: { xs: "0 2rem", md: "0" },
          }}
        >
          <Typography
            component="h1"
            sx={{
              color: "var(--color-blue)",
              fontSize: { xs: "3rem", sm: "3.6rem" },
              fontWeight: "600",
              textTransform: "uppercase",
              letterSpacing: "-0.5px",
              fontFamily: "var(--font-title)",
            }}
          >
            {t("component.wineTastings")}
          </Typography>
          <Typography
            sx={{
              fontSize: "2rem",
              width: { md: "75ch" },
              textAlign: "center",
              fontFamily: "var(--font-text)",
            }}
          >
            {t("component.experiencesHeader")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "12rem" }}>
          <TastingOptions t={t} />
          <RestaurantOncePueblos t={t} />
          <WineTerrace t={t} />
          <Airbnb t={t} />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
