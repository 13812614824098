import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SwitchLanguage from "../SwitchLanguage/SwitchLanguage";
import DrawerLeft from "../DrawerNav/Drawer";
import { useTranslation } from "react-i18next";
import { FacebookRounded, Instagram } from "@mui/icons-material";
import IconCart from "../IconCart/IconCart";
import { useDispatch, useSelector } from "react-redux";
import { navScroll, openShoppingCart } from "../Redux/actions";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import { LogoName } from "../assets/LogoName";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link, useLocation, useNavigate } from "react-router-dom";
import style from "./NavBar.module.css";

function NavBar() {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const NavigateScroll = useSelector((state) => state.navScroll);
  const pages = [
    t("component.aboutUs"),
    //    t("component.shopOnLine"),
    t("component.experiences"),
    t("component.contact"),
  ];
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    scrollotherview();
  }, [NavigateScroll.length]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const scrollotherview = () => {
    const aboutUs = document.getElementById("section--about-us");
    const contactUs = document.getElementById("section--contact-us");
    switch (NavigateScroll) {
      case t("component.aboutUs"):
        aboutUs.scrollIntoView({ behavior: "smooth" });
        break;
      case t("component.contact"):
        contactUs.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        return;
    }
  };

  const navigatePage = (e) => {
    dispatch(navScroll(e.target.innerHTML));
    if (location.pathname !== "/" && pages.includes(e.target.innerHTML)) {
      navigate("/");
    }
    const aboutUs = document.getElementById("section--about-us");
    const contactUs = document.getElementById("section--contact-us");
    switch (e.target.innerHTML) {
      case t("component.aboutUs"):
        aboutUs.scrollIntoView({ behavior: "smooth" });
        break;
      case t("component.contact"):
        contactUs.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        return;
    }
  };

  return (
    <>
      <ShoppingCart />
      <div onClick={navigatePage} className={style.nav_container}>
        <AppBar position="static">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Link to="/">
                <Box sx={{ display: { xs: "none", lg: "flex" }, mr: 1 }}>
                  <LogoName />
                </Box>
              </Link>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleDrawerOpen}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <DrawerLeft
                  open={open}
                  handleDrawerClose={handleDrawerClose}
                  navigatePage={navigatePage}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 3,
                  display: { xs: "flex", lg: "none" },
                  mr: 1,
                  justifyContent: "start",
                  marginRight: "0px",
                }}
              >
                <Link to="/">
                  <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                    <LogoName height="40px" />
                  </Box>
                  <Box sx={{ display: { xs: "flex", sm: "none" } }}>
                    <LogoName height="20px" />
                  </Box>
                </Link>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", lg: "flex" },
                  justifyContent: { md: "end" },
                }}
              >
                {pages.map((page) => (
                  <Button
                    className={style.button_pages}
                    key={page}
                    onClick={handleDrawerClose}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {page !== t("component.experiences") ? (
                      <Typography
                        sx={{
                          fontSize: "1.4rem",
                          fontFamily: "var(--font-text)",
                        }}
                      >
                        {page}
                      </Typography>
                    ) : (
                      <Link
                        className={`${style.button_pages} ${style.experience_link}`}
                        to="/experiences"
                      >
                        {t("component.experiences")}
                      </Link>
                    )}
                  </Button>
                ))}
                <Button sx={{ display: "flex" }}>
                  <a
                    href="https://goo.gl/maps/KiF9t4fuFdpNinaK8"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={style.button_location}
                  >
                    <LocationOnIcon
                      sx={{ marginTop: "2px", marginRight: "3px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "1.4rem",
                        fontFamily: "var(--font-text)",
                        textTransform: "uppercase",
                      }}
                    >
                      {t("component.location")}
                    </Typography>
                  </a>
                </Button>
              </Box>

              <Box sx={{ flexGrow: 0, display: { xs: "none", lg: "flex" } }}>
                <SwitchLanguage />
              </Box>

              <Box
                sx={{ flexGrow: 0 }}
                onClick={() => {
                  dispatch(openShoppingCart());
                }}
              >
                <IconCart />
              </Box>

              <Box sx={{ display: { xs: "none", sm: "flex" }, gap: "1.2rem" }}>
                <a
                  className={style.social_link}
                  href="https://www.facebook.com/sierravita/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookRounded sx={{ fontSize: "3.4rem" }} />
                </a>
                <a
                  className={style.social_link}
                  href="https://www.instagram.com/sierravitawinery/?hl=es"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram sx={{ fontSize: "3.4rem" }} />
                </a>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
    </>
  );
}
export default NavBar;
