import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ContactSierraVita from "../../Modals/ContactSierraVita/ContactSierraVita";
import { contactSierraVita } from "../../Redux/actions";
import styles from "./Contact.module.css";

export default function Contact() {
  const [t] = useTranslation("global");

  const [input, setInput] = useState({ name: "", email: "", text: "" });
  const [modal, setModal] = useState(false);

  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const resp = await contactSierraVita({ ...input });

    if (resp.status === 200) setModal(true);
    setInput({ name: "", email: "", text: "" });
  };

  return (
    <>
      <Box
        id="section--contact-us"
        component="section"
        sx={{
          padding: "8rem 2rem",
          backgroundColor: "var(--color-background)",
        }}
      >
        <Grid
          container
          className="section_hidden section"
          spacing={{ xs: 2, md: 4 }}
          justifyContent="center"
          alignItems="center"
          sx={{ flexDirection: { md: "row-reverse" } }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{ fontSize: { xs: "1.4rem", md: "1.6rem" } }}
          >
            <Typography
              variant="h2"
              component="h2"
              sx={{
                color: "var(--color-blue)",
                fontFamily: "var(--font-title)",
              }}
            >
              {t("component.contactUs")}
            </Typography>
            <Typography
              sx={{
                color: "var(--color-text-light)",
                marginBottom: "1.2rem",
                fontSize: "1.4rem",
                fontFamily: "var(--font-text)",
              }}
            >
              {t("component.hereForYou")}
            </Typography>
            <form className={styles.contact_form} onSubmit={handleSubmit}>
              <div className={styles.contact_input}>
                <label htmlFor="name">{t("component.name")}</label>
                <input
                  onChange={handleInput}
                  className={styles.input_field}
                  type="text"
                  id="name"
                  name="name"
                  value={input.name}
                />
              </div>
              <div className={styles.contact_input}>
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  onChange={handleInput}
                  className={styles.input_field}
                  type="email"
                  id="email"
                  value={input.email}
                />
              </div>
              <div className={styles.contact_input}>
                <label htmlFor="message">{t("component.message")}</label>
                <textarea
                  name="text"
                  onChange={handleInput}
                  className={`${styles.input_field} ${styles.message}`}
                  id="message"
                  rows="8"
                  value={input.text}
                ></textarea>
              </div>
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  textTransform: "none",
                  alignSelf: "center",
                  fontSize: "inherit",
                  color: "var(--color-text-light)",
                  borderColor: "currentcolor",
                  "&:hover": { borderColor: "var(--color-blue)" },
                }}
              >
                {t("component.submit")}
              </Button>
            </form>
          </Grid>
          <Grid item xs={12} md={6}>
            <iframe
              className={styles.map}
              title="Sierra Vita's location"
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/place?q=Vinicola+Sierravita&key=AIzaSyBmv7ldtsGd2PDnI0sCSJfrlNUuA4GuaX0`}
            ></iframe>
          </Grid>
        </Grid>
      </Box>
      <ContactSierraVita modal={modal} setModal={setModal} />
    </>
  );
}
