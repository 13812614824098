import React from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Paper from '@mui/material/Paper';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useDispatch, useSelector } from 'react-redux'
import { getOrdersAdmin } from '../../Redux/actions'
import OrderExpanded from './OrderExpanded';
import style from './Orders.module.css'


const Orders = () => {

  const AllOrders = useSelector(state => state.adminOrders)
  const [allOrders, setAllOrders] = React.useState([])
  const [drawer, setDrawer] = React.useState(false);
  const [orderId, setOrderId] = React.useState('');
  const [selectStateFilter, setSelectStateFilter] = React.useState('');
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (AllOrders.length === 0) {
      dispatch(getOrdersAdmin())
    }
    setAllOrders(AllOrders)
  }, [AllOrders.length])

  const handleChange = (e) => {
    let idOrderSearch = AllOrders.filter(o => o._id.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
    if (idOrderSearch.length === 0) {
      setAllOrders(null)
    } else setAllOrders(idOrderSearch)
  };

  const handleChangeSelectFilter = (e) => {
    setSelectStateFilter(e.target.value)
    if (e.target.value === "sent") {
      let wines = AllOrders.filter((wines) => { return wines.isSent === true })
      if (wines.length === 0) {
        return setAllOrders(null)
      } else setAllOrders(wines)
    } else if (e.target.value === "notSent") {
      let wines = AllOrders.filter((wines) => { return wines.isSent === false })
      if (wines.length === 0) {
        return setAllOrders(null)
      } else setAllOrders(wines)
    } else return setAllOrders(AllOrders)
  }

  const moreInformation = (id) => {
    setOrderId(id)
    setDrawer(true)
  }

  return (
    <>
      <Box className={style.container_box}>
        <OrderExpanded openDrawer={drawer} closeDrawer={setDrawer} id={orderId} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ m: 1, minWidth: 150, marginBottom: '30px' }}>
            <InputLabel id="demo-simple-select-label" sx={{ color: '#000', fontSize: '1.6rem' }}>Filter</InputLabel>
            <Select
              sx={{ color: '#000', fontSize: '1.6rem' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectStateFilter}
              label="filter"
              onChange={(e) => { handleChangeSelectFilter(e) }}
            >
              <MenuItem value='all' sx={{ color: '#000', fontSize: '1.6rem' }}>All</MenuItem>
              <MenuItem value='sent' sx={{ color: '#000', fontSize: '1.6rem' }}>Sent</MenuItem>
              <MenuItem value='notSent' sx={{ color: '#000', fontSize: '1.6rem' }}>Not Sent</MenuItem>
            </Select>
          </FormControl>
          <Box className={style.search_box}>
            <ManageSearchIcon sx={{ fontSize: '25px', background: '#fff', border: 'solid 1px #fff' }} />
            <input type="text" placeholder='Search by order number' onChange={(e) => { handleChange(e) }} />
          </Box>
        </Box>
        <TableContainer component={Paper} className={style.table_container} >
          <Table aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">N° Order</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Buyer</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Email</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Phone</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Date</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">Shipping status</TableCell>
                <TableCell sx={{ fontSize: '1.6rem' }} align="center">More info.</TableCell>
              </TableRow>
            </TableHead>
            {allOrders === null ? <h1>There are no orders to show</h1> : allOrders.length < 1 ? <h1>loading</h1> :
              <TableBody>
                {allOrders.map((order) => (
                  <TableRow
                    key={order._id}
                    className={style.table_row}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{order.orderN}</TableCell>
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{order.name}</TableCell>
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{order.email}</TableCell>
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{order.phone}</TableCell>
                    <TableCell sx={{ fontSize: '1.5rem' }} align="center">{new Date(order.createdAt).toLocaleDateString("es")}</TableCell>
                    {order.isSent ?
                      <TableCell sx={{ fontSize: '1.5rem', color: 'green', fontWeight: '600' }} align="center">Sent</TableCell>
                      :
                      <TableCell sx={{ fontSize: '1.5rem', color: 'red' }} align="center">Not Sent</TableCell>
                    }
                    <TableCell align="center">
                      <ControlPointIcon onClick={() => { moreInformation(order._id) }} sx={{ color: '#61a6ab', cursor: 'pointer' }} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            }
          </Table>
        </TableContainer >
      </Box>
    </>
  )
}

export default Orders;
