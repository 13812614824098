import { useEffect } from "react";
import AboutUs from "./AboutUs/AboutUs";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import MainBanerSection from "./MainBanerSection/MainBanerSection";
import Process from "./Process/Process";
import Vineyard from "./Vineyard/Vineyard";
import Banner from "./Banner/Banner";
import TastingGallery from "./Tasting/TastingGallery";
import Wines from "./Wines/Wines";
import { useDispatch, useSelector } from "react-redux";
import { emptyWineAddedToCart, getWines } from "../Redux/actions";
import Newsletter from "./Newsletter/Newsletter";
import { Box } from "@mui/material";

export default function Home() {
  const dispatch = useDispatch();
  const wineAddedToCart = useSelector((state) => state.lastWineAddedToCart);

  useEffect(() => {
    dispatch(getWines());
  }, []);

  window.addEventListener("keydown", (e) => {
    const key = e.key;
    if (key === "Escape" && Object.keys(wineAddedToCart).length > 0)
      dispatch(emptyWineAddedToCart());
  });

  return (
    <Box component="main" sx={{ position: "relative" }}>
      <MainBanerSection />
      <Wines />
      <Banner />
      <AboutUs />
      <Process />
      <Vineyard />
      <TastingGallery />
      <Newsletter />
      <Contact />
      <Footer />
    </Box>
  );
}
