import style from "./Tasting.module.css";

export default function ImageDisplay({ image, imageOpened, setImageOpened }) {
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape" && imageOpened) setImageOpened(false);
  });

  document.querySelector("main").addEventListener("click", () => {
    if (imageOpened) setImageOpened(false);
  });

  return (
    <>
      <img
        className={style.img_big}
        src={image}
        alt="Process of collecting the grape"
      />
      <div
        onClick={() => setImageOpened(false)}
        className={style.overlay}
      ></div>
    </>
  );
}
