import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux";
import { openShoppingCart } from '../Redux/actions';
import style from './ShoppingCart.module.css'
import { Typography } from '@mui/material';
import ProductCart from './ProductCart/ProductCart';
import { Link } from 'react-router-dom';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 1),
  backgroundColor: '#121212',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

function ShoppingCart() {

  const [t] = useTranslation("global")
  const theme = useTheme();
  const CartOpenState = useSelector(state => state.openShoppingCart);
  const itemsShoppingCart = useSelector(state => state.itemsShoppingCart);
  let dispatch = useDispatch();

  return (
    <Box sx={{ display: 'flex' }} >
      <Drawer
        sx={{
          flexShrink: 0,
        }}
        anchor="right"
        open={CartOpenState}
        onClose={() => dispatch(openShoppingCart())}
      >
        <DrawerHeader>
          <IconButton onClick={() => dispatch(openShoppingCart())} >
            {theme.direction === 'ltr' ? <ChevronRightIcon sx={{ color: '#fefdfd' }} /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ backgroundColor: '#fefdfd' }} />
        {itemsShoppingCart.length > 0 ?
          <Box className={style.products_box} sx={{ height: '1000vw', width: { xs: '250px', sm: '400px' } }}>
            <Typography sx={{ margin: '5px 30px', color: '#61a6ab', fontSize: '1.6rem' }}>
              {t("component.yourShoppingCart")}
            </Typography>
            <ProductCart />
            <Divider sx={{ backgroundColor: '#fefdfd' }} />
            <Link to='/checkout' className={style.link_checkout} >
              {t("component.checkout")}
            </Link>
          </Box>
          :
          <Box className={style.products_box} sx={{ height: '1000vw' }}>
            <Typography sx={{ margin: '20px', color: '#61a6ab', fontSize: '1.6rem' }}>
              {t("component.shoppingCartEmpty")}
            </Typography>
          </Box>
        }
      </Drawer>
    </Box>
  );
}

export default ShoppingCart;