import React, { useState, useEffect } from "react";
import Admins from "./Admins/Admins";
import LoginSupAdmin from "./LoginSupAdmin";

const RouterGuardSupAdmin = ({ component: Component, ...rest }) => {
  const [tokenadmin, setTokenadmin] = useState("");

  useEffect(() => {
    if (!tokenadmin || tokenadmin.length < 0) {
      setTokenadmin(sessionStorage.getItem("tokenadmin"));
    }
  }, [tokenadmin]);

  return <>{tokenadmin ? <Admins /> : <LoginSupAdmin />}</>;
};

export default RouterGuardSupAdmin;
