import React, { useCallback, useState } from "react";
import { Grid, Button, Typography } from '@mui/material';
import Wines from "./EditWine/Wines";
import Newsletter from "./Newsletters/Newsletter";
import RouterGuardSupAdmin from "./RouterGuardSupAdmin";
import Orders from "./Orders/Orders";

function Admin() {
  const [selected, setSelected] = useState(1);

  return (
    <Grid
      container
      spacing={{ sm: 2, md: 4 }}
      sx={{ 
        backgroundColor: "var(--color-background-admin)",
      }}
    >
      <Grid item xs={12} sm={12} md={12} textAlign="center" marginTop="3.5rem">
        <Button sx={{ backgroundColor: "var(--color-gray)", marginRight: "1rem", padding: "1rem", border: "1px solid var(--color-text-dark)" }} onClick={() => setSelected(1)} >
          <Typography
            sx={{
              fontSize: { xs: "1rem", sm: "1.5rem" },
              fontFamily: "var(--font-text)",
              color: "var(--color-text-dark)",
              fontWeight: "900"
            }}>Wines
            </Typography>
          </Button>
        <Button sx={{ backgroundColor: "var(--color-gray)", marginRight: "1rem", padding: "1rem", border: "1px solid var(--color-text-dark)" }}  onClick={() => setSelected(2)} >
        <Typography
            sx={{
              fontSize: { xs: "1rem", sm: "1.5rem" },
              fontFamily: "var(--font-text)",
              color: "var(--color-text-dark)",
              fontWeight: "900"
            }}>Newsletter</Typography>
            </Button>
        <Button sx={{ backgroundColor: "var(--color-gray)", marginRight: "1rem", padding: "1rem", border: "1px solid var(--color-text-dark)" }}  onClick={() => setSelected(3)} >
        <Typography
            sx={{
              fontSize: { xs: "1rem", sm: "1.5rem" },
              fontFamily: "var(--font-text)",
              color: "var(--color-text-dark)",
              fontWeight: "900"
            }}>Superadmin</Typography>
          </Button>
        <Button sx={{ backgroundColor: "var(--color-gray)", marginRight: "1rem", padding: "1rem", border: "1px solid var(--color-text-dark)" }}  onClick={() => setSelected(4)} >
        <Typography
            sx={{
              fontSize: { xs: "1rem", sm: "1.5rem" },
              fontFamily: "var(--font-text)",
              color: "var(--color-text-dark)",
              fontWeight: "900"
            }}>Orders</Typography>
          </Button>
      </Grid>
      <Grid style={{ display: selected === 1 ? "block" : "none" }} item xs={12} sm={12} md={12}>
        <Wines />
      </Grid>
      <Grid style={{ display: selected === 2 ? "block" : "none" }} item xs={12} sm={12} md={12}>
        <Newsletter />
      </Grid>
      <Grid style={{ display: selected === 3 ? "block" : "none" }} item xs={12} sm={12} md={12}>
        <RouterGuardSupAdmin />
      </Grid>
      <Grid style={{ display: selected === 4 ? "block" : "none" }} item xs={12} sm={12} md={12}>
        <Orders />
      </Grid>
    </Grid>
  )
}
export default Admin;