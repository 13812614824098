import { ExpandLessOutlined } from "@mui/icons-material";
import style from "./ScrollButton.module.css";

export default function ScrollButton() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button onClick={scrollToTop} className={style.scroll_btn}>
      <ExpandLessOutlined sx={{ fontSize: "3.4rem" }} />
    </button>
  );
}
