import { Box, Stack, Typography, Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { unsubscribeToNewsLetter } from '../Redux/actions';

function Unsubscribe() {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const [data, setData] = useState({ email: "" })
  const defaultValues = () => ({ email: "" });

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(unsubscribeToNewsLetter(data));
      setData({
        email: ""
      })
    alert("You're unsubscribe")
  };

  return (
    <Box
    direction="column"
    component="form"
    defaultValues={defaultValues}
    onSubmit={e => onSubmit(e)}
    sx={{
      '& .MuiTextField-root': { 
        m: 1, width: '40ch' 
      },
    }}
    >
      <Stack direction="column" alignItems="center" >
        <Typography 
          component="h1" 
          marginTop={15} 
          gutterBottom sx={{ 
          fontSize: "3.5rem", 
          color: "var(--color-blue)",
          fontFamily: "var(--font-title)",
          textAlign: "center",
          marginInline: "1rem",
          }}>
          {t("component.unsubscribe")}
        </Typography>
        <Typography 
          component="h3" 
          marginTop={1.5} 
          gutterBottom sx={{ 
          fontSize: "1.5rem",
          fontFamily: "var(--font-text)",
          color: "var(--color-text-light)",
          textAlign: "center",
          marginInline: "4rem",
          }}>
          {t("component.unsubscribeText")}
        </Typography>
          <TextField
            name="email"
            label="Email:"
            placeholder="example@example.com"
            onChange={e => handleChange(e)}
          />
        <Button 
          type="submit" 
          variant="outlined"
          onClick={onSubmit}
        >  
        {t("component.send")}
        </Button>

      </Stack>
    </Box>
  )
}

export default Unsubscribe;